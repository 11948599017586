import { CenteredDiv } from '@wavetronix/common-components'
import React, { useEffect, useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ColorOptions } from '../../utils/ColorOptions'
import StatsRmaModal from '../modals/StatsRmaModal'
import { NOT_SET, NOT_SET_COLOR } from '../RmaStatistics'
import CustomLegend from './CustomLegend'
import CustomToolTip from './CustomToolTip'

export default function StatsTimeGraph({ data, type, title }) {
  const [tooltip, setToolTip] = useState()
  const [legend, setLegend] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [rmas, setRmas] = useState([])

  useEffect(() => {
    let tempLegend = {}
    if (data && data.length > 0 && data[0].value) {
      let sorted = data[0].value.listOptions.sort()
      for (let j = 0; j < sorted.length; j++) {
        if (sorted[j] === NOT_SET) {
          tempLegend[sorted[j]] = NOT_SET_COLOR
        } else {
          tempLegend[sorted[j]] = Object.keys(ColorOptions).map(key => ColorOptions[key])[j % Object.keys(ColorOptions).length]
        }
      }
      setLegend(tempLegend)
    }
  }, [data])
  const setBars = (bar, option) => {
    if (type === 'Count') {
      return [...bar.payload.value.rmas]
    }
    if (type === 'boardType') {
      if (option === NOT_SET) {
        return [...bar.payload.value.rmas].filter(r => r[type].map(board => board['boardTypeName']).includes(''))
      } else return [...bar.payload.value.rmas].filter(r => [...r[type]].map(board => board['boardTypeName']).includes(option))
    } else {
      if (option === NOT_SET) {
        return [...bar.payload.value.rmas].filter(r => r[type] === '')
      } else return [...bar.payload.value.rmas].filter(r => r[type] === option)
    }
  }
  if (data) {
    return (
      <>
        <StatsRmaModal open={modalOpen} onClose={() => setModalOpen(false)} rmas={rmas} />
        <div style={{ width: '100%' }}>
          <CenteredDiv>
            <h5>{title}</h5>
          </CenteredDiv>
          <ResponsiveContainer width='99%' height={600}>
            <ComposedChart data={data}>
              <CartesianGrid strokeDasharray='3 8' />
              <XAxis dataKey='key' angle={-30} interval={0} height={80} textAnchor='end'></XAxis>
              <YAxis allowDecimals={false}>
                <Label value='RMA Count' offset={10} position='insideLeft' angle={-90} />
              </YAxis>
              <Tooltip content={<CustomToolTip tooltip={tooltip} />} />
              {data.length > 0 && data[0].value
                ? data[0].value.listOptions.map((option, index) => {
                    return (
                      <Bar
                        key={`${option}${index}`}
                        dataKey={`value.optionsCount[${option}]`}
                        stackId='a'
                        onMouseOver={() => setToolTip(option)}
                        onClick={bar => {
                          setRmas(setBars(bar, option))
                          setModalOpen(true)
                        }}
                        cursor='pointer'
                        fill={
                          option === NOT_SET
                            ? NOT_SET_COLOR
                            : Object.keys(ColorOptions).map(key => ColorOptions[key])[
                                data[0].value.listOptions.indexOf(option) % Object.keys(ColorOptions).length
                              ]
                        }>
                        {index === data[0].value.listOptions.length - 1 ? (
                          <LabelList dataKey={`value.count`} position='top' />
                        ) : (
                          <></>
                        )}
                      </Bar>
                    )
                  })
                : []}
            </ComposedChart>
          </ResponsiveContainer>
          <CustomLegend legend={legend} />
        </div>
      </>
    )
  } else {
    return <CenteredDiv>Loading...</CenteredDiv>
  }
}
