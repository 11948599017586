import { WtxColors, CenteredDiv } from '@wavetronix/common-components'
import React from 'react'
import { useState, useEffect } from 'react'
import CustomResponsiveContainer from './CustomResponsiveContainer'
import { ComposedChart, Brush, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts'
import StatsRmaModal from '../modals/StatsRmaModal'

export default function StatsGraph({
  data = [],
  title,
  xAxisLabel,
  yAxisLabel,
  interval = 0,
  autoResize = false,
  tooltip = null,
  enableBrush = true
}) {
  const [pointer, setpointer] = useState(false)
  const [rmas, setRmas] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const [brushWidth, setBrushWidth] = useState(0)
  const [brushStart, setBrushStart] = useState(0)

  useEffect(() => {
    let newWidth = Math.min(Math.floor(containerWidth / 100), data.length - 1)
    if (newWidth > 0 && autoResize) {
      setBrushWidth(Math.min(...[newWidth + brushStart, data.length - 1]))
    }
  }, [containerWidth, data.length, autoResize, brushStart])

  const BASE_HEIGHT = 100
  return (
    <>
      <StatsRmaModal open={modalOpen} onClose={() => setModalOpen(false)} rmas={rmas} />
      <div style={{ width: '100%' }}>
        <CenteredDiv>
          <h5>{title}</h5>
        </CenteredDiv>
        <CustomResponsiveContainer width='99%' height={350} setWidth={setContainerWidth}>
          <ComposedChart
            data={data}
            onClick={active => {
              if (active && active.payload) {
                setRmas(active.activePayload[0].payload.rmas)
                setModalOpen(true)
              }
            }}
            onMouseMove={active => {
              if (active.isTooltipActive) {
                setpointer(true)
              } else setpointer(false)
            }}
            cursor={pointer ? 'pointer' : 'default'}
          >
            {enableBrush ? (
              <Brush
                dataKey='name'
                onChange={change => {
                  if (!autoResize) {
                    setBrushWidth(change.endIndex < data.length ? change.endIndex : data.length - 1)
                  }
                  setBrushStart(change.startIndex)
                }}
                endIndex={brushWidth < data.length ? brushWidth : data.length - 1}
                startIndex={brushStart}
                height={30}
                stroke={WtxColors.IQ_BLUE}
              />
            ) : (
              <></>
            )}
            <CartesianGrid strokeDasharray='3 8' />
            <XAxis
              dataKey='item'
              angle={-30}
              interval={interval}
              height={BASE_HEIGHT}
              textAnchor='end'
              tickFormatter={label => (label.length > 10 ? `${label.slice(0, 10)}...` : label)}
            >
              <Label value={xAxisLabel} offset={20} position='insideBottom' />
            </XAxis>
            <YAxis allowDecimals={false}>
              <Label value={yAxisLabel} offset={10} position='insideLeft' angle={-90} />
            </YAxis>
            <Tooltip content={tooltip} />
            <Bar
              minPointSize={1}
              dataKey='count'
              fill={WtxColors.IQ_BLUE}
              barSize={20}
              onClick={bar => {
                setRmas([...bar.rmas])
                setModalOpen(true)
              }}
              cursor='pointer'
            />
          </ComposedChart>
        </CustomResponsiveContainer>
      </div>
    </>
  )
}
