// Modified code from Recharts library's ResponsiveContainer

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactResizeDetector from 'react-resize-detector'
import _ from 'lodash'

const isPercent = value => _.isString(value) && value.indexOf('%') === value.length - 1

const isDev = process.env.NODE_ENV !== 'production'

const warn = (condition, format, a, b, c, d, e, f) => {
  if (isDev && typeof console !== 'undefined' && console.warn) {
    if (format === undefined) {
      console.warn('LogUtils requires an error message argument')
    }

    if (!condition) {
      if (format === undefined) {
        console.warn(
          'Minified exception occurred; use the non-minified dev environment ' +
            'for the full error message and additional helpful warnings.'
        )
      } else {
        const args = [a, b, c, d, e, f]
        let argIndex = 0

        console.warn(format.replace(/%s/g, () => args[argIndex++]))
      }
    }
  }
}

class CustomResponsiveContainer extends Component {
  static displayName = 'CustomResponsiveContainer'

  static propTypes = {
    aspect: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
    debounce: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setWidth: PropTypes.func
  }

  static defaultProps = {
    width: '100%',
    height: '100%',
    debounce: 0
  }

  constructor(props) {
    super(props)

    this.state = {
      containerWidth: -1,
      containerHeight: -1
    }

    this.handleResize =
      props.debounce > 0 ? _.debounce(this.updateDimensionsImmediate, props.debounce) : this.updateDimensionsImmediate
  }

  /* eslint-disable  react/no-did-mount-set-state */
  componentDidMount() {
    this.mounted = true

    const size = this.getContainerSize()

    if (size) {
      this.setState(size)
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getContainerSize() {
    if (!this.container) {
      return null
    }

    this.props.setWidth(this.container.clientWidth)

    return {
      containerWidth: this.container.clientWidth,
      containerHeight: this.container.clientHeight
    }
  }

  updateDimensionsImmediate = () => {
    if (!this.mounted) {
      return
    }

    const newSize = this.getContainerSize()

    if (newSize) {
      const { containerWidth: oldWidth, containerHeight: oldHeight } = this.state
      const { containerWidth, containerHeight } = newSize

      if (containerWidth !== oldWidth || containerHeight !== oldHeight) {
        this.setState({ containerWidth, containerHeight })
      }
    }
  }

  renderChart() {
    const { containerWidth, containerHeight } = this.state

    if (containerWidth < 0 || containerHeight < 0) {
      return null
    }

    const { aspect, width, height, minWidth, minHeight, maxHeight, children } = this.props

    warn(
      isPercent(width) || isPercent(height),
      `The width(%s) and height(%s) are both fixed numbers,
       maybe you don't need to use a CustomResponsiveContainer.`,
      width,
      height
    )

    warn(!aspect || aspect > 0, 'The aspect(%s) must be greater than zero.', aspect)

    const calculatedWidth = isPercent(width) ? containerWidth : width
    let calculatedHeight = isPercent(height) ? containerHeight : height

    if (aspect && aspect > 0) {
      // Preserve the desired aspect ratio
      calculatedHeight = calculatedWidth / aspect
      // if maxHeight is set, overwrite if calculatedHeight is greater than maxHeight
      if (maxHeight && calculatedHeight > maxHeight) {
        calculatedHeight = maxHeight
      }
    }

    warn(
      calculatedWidth > 0 || calculatedHeight > 0,
      `The width(%s) and height(%s) of chart should be greater than 0,
       please check the style of container, or the props width(%s) and height(%s),
       or add a minWidth(%s) or minHeight(%s) or use aspect(%s) to control the
       height and width.`,
      calculatedWidth,
      calculatedHeight,
      width,
      height,
      minWidth,
      minHeight,
      aspect
    )

    return React.cloneElement(children, {
      width: calculatedWidth,
      height: calculatedHeight
    })
  }

  render() {
    const { minWidth, minHeight, width, height, maxHeight, id, className } = this.props
    const style = { width, height, minWidth, minHeight, maxHeight }

    return (
      <div
        id={id}
        className={classNames('recharts-responsive-container', className)}
        style={style}
        ref={node => {
          this.container = node
        }}>
        {this.renderChart()}
        <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
      </div>
    )
  }
}

export default CustomResponsiveContainer
