// https://stackoverflow.com/questions/55301556/recharts-tooltip-for-each-bar-in-a-bar-chart-composed-of-three-bars
export default function CustomToolTip({ active, payload, tooltip }) {
  if (!active || !tooltip || !payload) return null
  for (const bar of payload) {
    if (bar.dataKey === `value.optionsCount[${tooltip}]`) {
      return (
        <div
          style={{
            backgroundColor: bar.color,
            padding: '20px',
            opacity: 0.9,
            border: '2px solid white',
            borderRadius: '15px',
            color: 'white'
          }}>
          Type: {tooltip}
          <br />
          Amount: {bar.payload.value.optionsCount[tooltip]}
        </div>
      )
    }
  }
  return null
}
