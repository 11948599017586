import { Card, CardContent, Chip, Divider, FormControlLabel, Grid, InputAdornment, Switch, TextField } from '@mui/material'
import { CustomAccordion, WtxColors } from '@wavetronix/common-components'
import dayjs from 'dayjs'
import { useState } from 'react'

const classes = {
  formEntry: {
    margin: '10px 10px 10px 0px',
    width: '205px'
  },
  AccordionEntry: {
    margin: '10px 0px 10px 0px'
  },
  card: {
    width: '85%',
    marginTop: '15px',
    border: 'none',
    boxShadow: 'none'
  },
  innerCard: {
    width: '100%',
    height: '95%',
    margin: '15px 0px 15px 0px'
  },
  detailInfo: { fontSize: '18px', marginTop: 'auto', marginLeft: '10px' },
  detailInfoDiv: { display: 'flex', marginTop: '20px' }
}

export default function ReadOnlyDetailView({ rma }) {
  const [expanded, setExpanded] = useState(false)

  const openAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Grid item md={12} lg={8}>
      <Card sx={classes.innerCard}>
        <CardContent>
          <Grid container justifyContent='space-evenly'>
            <Grid>
              <Grid container justifyContent='space-evenly' direction='column'>
                <p style={{ marginBottom: '-10px', fontWeight: 'bold' }}>Boards</p> <Divider style={{ ...classes.formEntry }} />
                {rma.boardType.map((board, index) => {
                  return (
                    <CustomAccordion
                      key={index}
                      style={{ width: '205px' }}
                      expanded={expanded === `${board.boardTypeName}`}
                      onChange={openAccordion(`${board.boardTypeName}`)}
                      title={board.boardTypeName !== '' ? board.boardTypeName : 'None Selected'}
                      titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                      detailStyle={{ marginTop: '10px' }}
                      children={
                        <>
                          {board.designators.map((designator, desIndex) => {
                            return (
                              <Chip key={desIndex} label={designator} style={{ margin: '2px 0px 0px 2px' }} variant='outlined' />
                            )
                          })}
                        </>
                      }
                    />
                  )
                })}
                <Grid>
                  <FormControlLabel
                    style={{ margin: '19px -10px 0px -10px' }}
                    control={<Switch disabled checked={rma.isRapidRepair ? true : false} />}
                    label='Rapid Repair'
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    style={{ margin: '0px -10px' }}
                    control={
                      <Switch disabled checked={rma.outOfBoxFailure === 'Yes' || rma.outOfBoxFailure === 'Y' ? true : false} />
                    }
                    label='Out Of Box Failure'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container justifyContent='space-evenly' direction='column'>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Board Rev'
                    value={rma.boardRev}
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                  />
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Cause of Problem Found'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.problemFound}
                  />
                </Grid>
                <Grid>
                  <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                    <TextField
                      size='small'
                      disabled
                      label='Completed Date'
                      value={rma.completedDate ? dayjs(rma.completedDate).format('MM/DD/YYYY') : ''}
                    />
                  </div>
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Repair Tech.'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.initials}
                  />
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Repair Comments'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.repairComments}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Grid container justifyContent='space-evenly' direction='column'>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Amount Waived'
                    value={rma.amountWaived ? rma.amountWaived : ''}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                  />
                  <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                    <TextField
                      size='small'
                      disabled
                      label='Original Ship Date'
                      value={rma.originalShipDate ? dayjs(rma.originalShipDate).format('MM/DD/YYYY') : ''}
                    />
                  </div>
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Amount Spent'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    value={rma.amountSpent}
                  />
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Customer Complaints'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.customerComplaint}
                  />
                </Grid>

                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Customer Complaints'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.finalDetermination}
                  />
                </Grid>
                <Grid>
                  <TextField
                    size='small'
                    disabled
                    label='Repair Types'
                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                    value={rma.repairType}
                  />
                </Grid>
                <Grid>
                  <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                    <TextField
                      size='small'
                      disabled
                      label='Build Date'
                      value={rma.buildDate ? dayjs(rma.buildDate).format('MM/DD/YYYY') : ''}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
