import { useMsal } from '@azure/msal-react'
import { Card, CardHeader, FormControlLabel, List, ListItem, ListItemButton, ListSubheader, Switch, Divider } from '@mui/material'
import { CustomSelect, RegularButton, WtxColors, PersistantFilterDiv, inDateRange } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import RmaApi from '../api/RmaApi'
import StateMapGraph from './graphs/StateMapGraph'
import StatsTimeGraph from './graphs/StatsTimeGraph'
import StatsGraph from './graphs/StatsGraph'
import StatsSelect from './StatsSelect'
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import { WidthProvider, Responsive } from 'react-grid-layout'
import StatsApi from '../api/StatsApi'
import RmaStatsFilterDrawer, { DEFAULT_RMASTATS_FILTER } from './drawers/RmaStatsFilterDrawer'
import dayjs from 'dayjs'
import ConsolidatedTooltip from './graphs/ConsolidatedTooltip'
import customerComplaintMap from '../utils/CustomerComplaintMap'
import productMap from '../utils/ProductMap'

const ReactGridLayout = WidthProvider(Responsive)

const classes = {
  card: {
    margin: '10px 10px 10px 0px',
    minHeight: '50px'
  },
  graph: {
    display: 'flex'
  },
  cardHeader: {
    background: WtxColors.CONCRETE,
    color: 'black'
  },
  select: { width: '20%', marginTop: 15, marginLeft: 15 }
}

export const NOT_SET = 'Not Set'
export const NOT_SET_COLOR = 'black'

export default function RmaStatistics({
  filter,
  setFilter,
  boardTypes,
  problemsFound,
  issues,
  customerComplaints,
  finalDeterminations,
  repairTypes,
  productTypes,
  products,
  customers,
  dealers,
  states
}) {
  const { instance, accounts } = useMsal()
  const [timedGraphDataOption, setTimedGraphDataOption] = useState('')
  const [designatorByProduct, setDesignatorByProduct] = useState('Default (All)')
  const [designatorByBoardOption, setDesignatorByBoardOption] = useState('')
  const [designatorByProductOption, setDesignatorByProductOption] = useState('')
  const [designatorByProductTypeOption, setDesignatorByProductTypeOption] = useState('')
  const history = useHistory()
  const [avgDaysOpen, setAvgDaysOpen] = useState(0)
  const [isDraggable, setIsDraggable] = useState(false)
  const [autoResize, setAutoResize] = useState(true)
  const [allOptionsList, setAllOptionsList] = useState({})

  const layoutLG = [
    { i: 'productType', x: 0, y: 0, w: 4, h: 2 },
    { i: 'product', x: 4, y: 0, w: 8, h: 2 },
    { i: 'allOptions', x: 0, y: 2, w: 7, h: 4 },
    { i: 'daysOpen', x: 8, y: 2, w: 5, h: 2 },
    { i: 'USAMap', x: 0, y: 5, w: 7, h: 3 },
    { i: 'daysOpenByCustomer', x: 8, y: 4, w: 5, h: 4 },
    { i: 'boardType', x: 0, y: 8, w: 4, h: 2 },
    { i: 'customerComplaint', x: 8, y: 8, w: 8, h: 2 },
    { i: 'repairType', x: 0, y: 10, w: 4, h: 2 },
    { i: 'problemFound', x: 4, y: 10, w: 8, h: 2 },
    { i: 'designator', x: 0, y: 14, w: 12, h: 3 },
    { i: 'customer', x: 0, y: 12, w: 12, h: 2 },
    { i: 'finalDetermination', x: 0, y: 16, w: 12, h: 2 },
    { i: 'dealer', x: 0, y: 18, w: 12, h: 2 }
  ]
  const layoutMD = [
    { i: 'productType', x: 0, y: 0, w: 6, h: 2 },
    { i: 'repairType', x: 6, y: 0, w: 6, h: 2 },
    { i: 'product', x: 6, y: 2, w: 6, h: 2 },
    { i: 'problemFound', x: 0, y: 4, w: 6, h: 2 },
    { i: 'daysOpen', x: 0, y: 4, w: 6, h: 2 },
    { i: 'daysOpenByCustomer', x: 6, y: 4, w: 6, h: 4 },
    { i: 'USAMap', x: 0, y: 6, w: 6, h: 2 },
    { i: 'boardType', x: 0, y: 6, w: 12, h: 2 },
    { i: 'customerComplaint', x: 6, y: 8, w: 12, h: 2 },
    { i: 'allOptions', x: 0, y: 8, w: 12, h: 4 },
    { i: 'designator', x: 6, y: 20, w: 12, h: 3 },
    { i: 'customer', x: 0, y: 18, w: 12, h: 2 },
    { i: 'finalDetermination', x: 6, y: 22, w: 12, h: 2 },
    { i: 'dealer', x: 0, y: 24, w: 12, h: 2 }
  ]
  const layoutXXS = [
    { i: 'productType', x: 0, y: 0, w: 1, h: 2 },
    { i: 'repairType', x: 0, y: 2, w: 1, h: 2 },
    { i: 'product', x: 0, y: 4, w: 1, h: 2 },
    { i: 'problemFound', x: 0, y: 6, w: 1, h: 2 },
    { i: 'boardType', x: 0, y: 8, w: 1, h: 2 },
    { i: 'customerComplaint', x: 0, y: 10, w: 1, h: 2 },
    { i: 'customer', x: 0, y: 12, w: 1, h: 2 },
    { i: 'designator', x: 0, y: 26, w: 1, h: 3 },
    { i: 'allOptions', x: 0, y: 14, w: 1, h: 3 },
    { i: 'daysOpen', x: 0, y: 17, w: 1, h: 2 },
    { i: 'daysOpenByCustomer', x: 0, y: 19, w: 1, h: 4 },
    { i: 'USAMap', x: 0, y: 23, w: 1, h: 3 },
    { i: 'finalDetermination', x: 0, y: 16, w: 12, h: 2 },
    { i: 'dealer', x: 0, y: 18, w: 12, h: 2 }
  ]

  const { data } = useQuery({ queryKey: ['RMA Requests'], queryFn: async () => await RmaApi.getRmas(instance, accounts) })
  const { data: repairTypeData, refetch: refetchRepairTypeData } = useQuery({
    queryKey: ['RepairType'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'RepairType' })
  })
  const { data: problemFoundData, refetch: refetchProblemFoundData } = useQuery({
    queryKey: ['ProblemFound'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'ProblemFound' })
  })
  const { data: productTypeData, refetch: refetchProductTypeData } = useQuery({
    queryKey: ['ProductType'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'ProductType' })
  })
  const { data: productData, refetch: refetchProductData } = useQuery({
    queryKey: ['Product'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'Product' })
  })
  const { data: DesignatorData, refetch: refetchDesignatorData } = useQuery({
    queryKey: ['Designator'],
    queryFn: async () => await StatsApi.GetDesignatorInfo(instance, accounts, { ...filter, dataAxisName: 'Designator' })
  })
  const { data: customerComplaintData, refetch: refetchCustomerComplaintData } = useQuery({
    queryKey: ['CustomerComplaint'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'CustomerComplaint' })
  })
  const { data: finalDeterminationData, refetch: refetchFinalDeterminationData } = useQuery({
    queryKey: ['FinalDetermination'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'FinalDetermination' })
  })
  const { data: customerData, refetch: refetchCustomerData } = useQuery({
    queryKey: ['Customer'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'Customer' })
  })
  const { data: dealerData, refetch: refetchDealerData } = useQuery({
    queryKey: ['Dealer'],
    queryFn: async () => await StatsApi.getDataGraphInfo(instance, accounts, { ...filter, dataAxisName: 'Dealer' })
  })
  const { data: boardTypeData, refetch: refetchBoardTypeData } = useQuery({
    queryKey: ['BoardType'],
    queryFn: async () => await StatsApi.GetBoardTypeInfo(instance, accounts, { ...filter, dataAxisName: 'BoardType' })
  })
  const { data: daysOpenData, refetch: refetchDaysOpenData } = useQuery({
    queryKey: ['DaysOpen'],
    queryFn: async () => await StatsApi.GetDaysOpenInfo(instance, accounts, { ...filter, dataAxisName: 'DaysOpen' })
  })
  const { data: stateData, refetch: refetchStateData } = useQuery({
    queryKey: ['State'],
    queryFn: async () => await StatsApi.GetStateData(instance, accounts, filter)
  })

  //All Options Data
  const { data: productDataTimed, refetch: refetchProductDataTimed } = useQuery({
    queryKey: ['ProductsMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'Product' })
  })
  const { data: repairTypeDataTimed, refetch: refetchRepairTypeDataTimed } = useQuery({
    queryKey: ['RepairTypesMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'RepairType' })
  })
  const { data: productTypeDataTimed, refetch: refetchProductTypeDataTimed } = useQuery({
    queryKey: ['ProductTypesMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'ProductType' })
  })
  const { data: problemFoundDataTimed, refetch: refetchProblemFoundDataTimed } = useQuery({
    queryKey: ['ProblemFoundMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'ProblemFound' })
  })
  const { data: customerComplaintDataTimed, refetch: refetchCustomerComplaintDataTimed } = useQuery({
    queryKey: ['CustomerComplaintMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'CustomerComplaint' })
  })
  const { data: finalDeterminationDataTimed, refetch: refetchFinalDeterminationDataTimed } = useQuery({
    queryKey: ['FinalDeterminationMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'CustomerComplaint' })
  })
  const { data: customerDataTimed, refetch: refetchCustomerDataTimed } = useQuery({
    queryKey: ['CustomerMonth'],
    queryFn: async () => await StatsApi.GetMonthlyInfo(instance, accounts, { ...filter, dataAxisName: 'Customer' })
  })
  const { data: boardTypeDataTimed, refetch: refetchBoardTypeDataTimed } = useQuery({
    queryKey: ['BoardTypesMonth'],
    queryFn: async () => await StatsApi.GetMonthlyBoardInfo(instance, accounts, { ...filter, dataAxisName: 'BoardType' })
  })
  const { data: allReturnsTimed, refetch: refetchAllReturnsTimed } = useQuery({
    queryKey: ['TotalsMonth'],
    queryFn: async () => await StatsApi.GetMonthlyTotalInfo(instance, accounts, { ...filter, dataAxisName: 'Count' })
  })
  const { data: trailing12Months, refetch: refetchTrailing12Months } = useQuery({
    queryKey: ['Trailing12Months'],
    queryFn: async () => await StatsApi.GetTrailing12Months(instance, accounts, { ...filter, dataAxisName: 'Trailing 12 Months' })
  })

  // const list = [
  //   { title: 'Total RMAs', type: 'Count', list: ['Count'], data: allReturnsTimed },
  //   { title: 'Board Types', type: 'boardType', list: boardTypes, data: boardTypeDataTimed },
  //   { title: 'Problems Found', type: 'problemFound', list: problemsFound, data: problemFoundDataTimed },
  //   { title: 'Customer Complaints', type: 'customerComplaint', list: customerComplaints, data: customerComplaintDataTimed },
  //   { title: 'Repair Types', type: 'repairType', list: repairTypes, data: repairTypeDataTimed },
  //   {
  //     title: 'Product Types',
  //     type: 'productType',
  //     list: productTypes ? productTypes.map(b => b.productTypeName) : [],
  //     data: productTypeDataTimed
  //   },
  //   { title: 'Products', type: 'product', list: products ? products.map(b => b.productName) : [], data: productDataTimed },
  //   { title: 'Customers', type: 'customer', list: customers ? customers.map(c => c.customerName) : [], data: customerDataTimed }
  // ]

  function cleanAndSort(data, map = {}) {
    if (data) {
      if (data.length > 0) {
        data = consolidateData(data, map)
        for (let datum of data) {
          if (datum.item === '') {
            datum.item = 'Not Set'
          }
        }
        return data.sort((a, b) => {
          return b.count - a.count
        })
      }
    }
  }

  function consolidateData(data, map = {}) {
    let result = []
    if (data) {
      for (let element of data) {
        let counted = false
        for (let key of Object.keys(map)) {
          if (map[key].includes(element.item.toLowerCase())) {
            if (result.map(x => x.item).includes(key)) {
              result.filter(x => x.item === key)[0].count += element.count
              result.filter(x => x.item === key)[0].subcategories.push({ item: element.item, count: element.count })
              result.filter(x => x.item === key)[0].rmas = result.filter(x => x.item === key)[0].rmas.concat(element.rmas)
            } else {
              result.push({
                item: key,
                count: element.count,
                subcategories: [{ item: element.item, count: element.count }],
                rmas: element.rmas
              })
            }
            counted = true
          }
        }
        if (result.filter(x => x.item).length && !counted) {
          result.push(element)
        } else if (!counted) {
          result.push(element)
        }
      }
    }
    return result
  }

  useEffect(() => {
    var productTypeList = productTypes ? productTypes.map(b => b.productTypeName) : []
    var productList = products ? products.map(b => b.productName) : []
    var customersList = customers ? customers.map(c => c.customerName) : []

    setAllOptionsList({
      'Total RMAs': { type: 'Count', list: ['Count'], data: allReturnsTimed },
      'Trailing 12 Months': { type: 'Count', list: ['Count'], data: trailing12Months },
      'Board Types': { type: 'boardType', list: boardTypes, data: boardTypeDataTimed },
      'Problems Found': { type: 'problemFound', list: problemsFound, data: problemFoundDataTimed },
      'Customer Complaints': { type: 'customerComplaint', list: customerComplaints, data: customerComplaintDataTimed },
      'Final Determinations': { type: 'finalDetermination', list: finalDeterminations, data: finalDeterminationDataTimed },
      'Repair Types': { type: 'repairType', list: repairTypes, data: repairTypeDataTimed },
      'Product Types': {
        type: 'productType',
        list: productTypeList,
        data: productTypeDataTimed
      },
      Products: { type: 'product', list: productList, data: productDataTimed },
      Customers: { type: 'customer', list: customersList, data: customerDataTimed }
    })
  }, [
    allReturnsTimed,
    trailing12Months,
    boardTypes,
    boardTypeDataTimed,
    problemsFound,
    problemFoundDataTimed,
    customerComplaints,
    customerComplaintDataTimed,
    finalDeterminations,
    finalDeterminationDataTimed,
    repairTypes,
    repairTypeDataTimed,
    productTypes,
    productTypeDataTimed,
    products,
    productDataTimed,
    customers,
    customerDataTimed
  ])

  useEffect(() => {
    function getDesignatorsOfRma(rma) {
      let result = []
      for (let board of rma.boardType) {
        for (let designator of board.designators) {
          result.push(designator)
        }
      }
      return Array.from(new Set(result))
    }
    function isWithinSelection(filterField, filterFieldName, rmaField) {
      if (filterField.map(x => x[filterFieldName]).includes(rmaField)) {
        return true
      }
      return false
    }
    function filteredData() {
      let result = data.filter(rma => {
        if (!dayjs(rma.openDate).isAfter(filter.openDateStart) && dayjs(rma.openDate).isBefore(filter.openDateEnd)) {
          return false
        }
        if (filter.dealer.length || filter.customer.length || filter.productType.length || filter.product.length) {
          if (filter.designator !== '') {
            if (!getDesignatorsOfRma(rma).includes(filter.designator)) {
              return false
            }
          }
          return (
            isWithinSelection(filter.dealer, 'dealerName', rma.dealer) ||
            isWithinSelection(filter.customer, 'customerName', rma.customer) ||
            isWithinSelection(filter.productType, 'productTypeName', rma.productType) ||
            isWithinSelection(filter.product, 'productName', rma.product)
          )
        }
        if (filter.designator !== '') {
          if (getDesignatorsOfRma(rma).includes(filter.designator)) {
          }
          return getDesignatorsOfRma(rma).includes(filter.designator)
        }
        return true
      })
      return result
    }

    let totalDaysOpen = 0
    let count = 0
    for (let rma of data ? filteredData().filter(rma => parseInt(rma.daysOpen)) : []) {
      if (parseInt(rma.daysOpen) >= 0) {
        totalDaysOpen += parseInt(rma.daysOpen)
        count += 1
      }
    }
    setAvgDaysOpen(
      data ? (!isNaN((totalDaysOpen / count).toFixed(2)) ? (totalDaysOpen / count).toFixed(2) : 'N/A') : 'loading...'
    )
  }, [data, filter])

  useEffect(() => {
    refetchRepairTypeData()
    refetchRepairTypeDataTimed()
    refetchAllReturnsTimed()
    refetchTrailing12Months()
    refetchBoardTypeData()
    refetchBoardTypeDataTimed()
    refetchCustomerComplaintData()
    refetchCustomerComplaintDataTimed()
    refetchFinalDeterminationData()
    refetchFinalDeterminationDataTimed()
    refetchCustomerData()
    refetchCustomerDataTimed()
    refetchDealerData()
    refetchDaysOpenData()
    refetchProblemFoundData()
    refetchProblemFoundDataTimed()
    refetchProductData()
    refetchProductDataTimed()
    refetchProductTypeData()
    refetchProductTypeDataTimed()
    refetchDesignatorData()
    refetchStateData()
  }, [
    filter,
    refetchRepairTypeData,
    refetchRepairTypeDataTimed,
    refetchAllReturnsTimed,
    refetchTrailing12Months,
    refetchBoardTypeData,
    refetchBoardTypeDataTimed,
    refetchCustomerComplaintData,
    refetchCustomerComplaintDataTimed,
    refetchFinalDeterminationData,
    refetchFinalDeterminationDataTimed,
    refetchCustomerData,
    refetchCustomerDataTimed,
    refetchDealerData,
    refetchDaysOpenData,
    refetchProblemFoundData,
    refetchProblemFoundDataTimed,
    refetchProductData,
    refetchProductDataTimed,
    refetchProductTypeData,
    refetchProductTypeDataTimed,
    refetchDesignatorData,
    refetchStateData
  ])

  const boards = data => {
    let boards = []
    if (data) {
      for (let rma of data) {
        for (let board of rma.boardType) {
          boards.push(board)
        }
      }
    }
    return boards
  }

  const designatorsByBoards = data => {
    let result = {}
    for (let board of boards(data)) {
      if (result[board.boardTypeName]) {
        for (let designator of board.designators) {
          if (result[board.boardTypeName][designator]) {
            result[board.boardTypeName][designator].count += 1
          } else {
            result[board.boardTypeName][designator] = {
              item: designator,
              count: 1
            }
          }
        }
      } else {
        result[board.boardTypeName] = {}
        for (let designator of board.designators) {
          result[board.boardTypeName][designator] = {
            item: designator,
            count: 1
          }
        }
      }
    }
    return result
  }

  const designatorsByProduct = data => {
    let result = {}
    if (data) {
      for (let rma of data) {
        for (let board of rma.boardType) {
          for (let designator of board.designators) {
            if (!result[rma.product]) {
              result[rma.product] = {}
            }
            if (result[rma.product][designator]) {
              result[rma.product][designator].count += 1
              result[rma.product][designator].rmas.push(rma)
            } else {
              result[rma.product][designator] = {
                item: designator,
                count: 1,
                rmas: [rma]
              }
            }
          }
        }
      }
    }
    return result
  }

  const designatorsByProductType = data => {
    let result = {}
    if (data) {
      for (let rma of data) {
        for (let board of rma.boardType) {
          for (let designator of board.designators) {
            if (!result[rma.productType]) {
              result[rma.productType] = {}
            }
            if (result[rma.productType][designator]) {
              result[rma.productType][designator].count += 1
              result[rma.productType][designator].rmas.push(rma)
            } else {
              result[rma.productType][designator] = {
                item: designator,
                count: 1,
                rmas: [rma]
              }
            }
          }
        }
      }
    }
    return result
  }

  const CustomerList = (filter, data) => {
    let customers = []
    if (data) {
      for (let rma of data) {
        if (inDateRange(rma.openDate, filter.start, filter.end) && rma.daysOpen) {
          customers.push(rma.customer)
        }
      }
      customers = [...new Set(customers)]
    }
    let dataByCustomer = []
    for (let customer of customers) {
      for (let rma of data) {
        if (rma.customer === customer && rma.daysOpen) {
          let found = dataByCustomer.find(item => item.customerName === customer)
          let daysOpen = parseInt(rma.daysOpen)
          if (daysOpen) {
            if (found) {
              found.daysOpen.push(daysOpen)
            } else {
              dataByCustomer.push({
                customerName: customer,
                daysOpen: [daysOpen]
              })
            }
          }
        }
      }
    }
    let customerInfo = []
    for (let rma of dataByCustomer) {
      customerInfo.push({
        customerName: rma.customerName,
        avgDaysOpen: rma.daysOpen.reduce((a, b) => a + b) / rma.daysOpen.length,
        maxDaysOpen: Math.max(...rma.daysOpen.map(a => a))
      })
    }
    customerInfo.sort((a, b) => {
      return b.maxDaysOpen - a.maxDaysOpen ? b.maxDaysOpen - a.maxDaysOpen : b.avgDaysOpen - a.avgDaysOpen
    })
    return (
      <div style={{ maxHeight: '100%', overflowY: 'scroll' }}>
        <List
          subheader={
            <ListSubheader component='div' id='nested-list-subheader' sx={{ ...classes.cardHeader, fontSize: '16px' }}>
              Most Days Open by Customer
            </ListSubheader>
          }
        >
          {customerInfo.map((customer, index) => (
            <ListItem
              id={`customer${index}ListItem`}
              disablePadding
              key={index}
              style={{
                width: '300px',
                backgroundColor:
                  customer.maxDaysOpen > 30
                    ? customer.maxDaysOpen > 99
                      ? 'red'
                      : `${WtxColors.INNOVATION_RED}${Math.round(customer.maxDaysOpen * 2.5).toString(16)}`
                    : `${WtxColors.GROWTH_GREEN}${Math.round(255 - customer.maxDaysOpen * 7).toString(16)}`
              }}
            >
              <ListItemButton id={`customer${index}ListItemButton`} style={{ justifyContent: 'space-between' }}>
                <div style={{ width: '200px' }}> {customer.customerName}</div>
                <div style={{ width: '85px', justifyContent: 'space-between' }}>
                  <div style={{ display: 'inline-block' }}>Avg: {customer.avgDaysOpen.toFixed(0)}</div>

                  <div style={{ display: 'inline-block' }}>Max: {customer.maxDaysOpen}</div>
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    )
  }
  return (
    <PersistantFilterDiv
      drawer={
        <RmaStatsFilterDrawer
          filter={filter}
          setFilter={setFilter}
          dealers={dealers}
          productTypes={productTypes}
          products={products}
          customers={customers}
        />
      }
      resetFilter={() => setFilter(DEFAULT_RMASTATS_FILTER)}
      page={
        <div>
          <div style={{ margin: '24px 10px 0px 10px' }}>
            <RegularButton id='mainPageButton' onClick={rma => history.push(`/`)}>
              Main Page
            </RegularButton>
            <FormControlLabel
              style={{ margin: '19px 0px 0px 0px' }}
              control={<Switch checked={isDraggable} onChange={e => setIsDraggable(!isDraggable)} />}
              label='Draggable'
            />
            <FormControlLabel
              style={{ margin: '19px 0px 0px 0px' }}
              control={<Switch checked={autoResize} onChange={e => setAutoResize(!autoResize)} />}
              label='Auto Resize Graphs'
            />
          </div>
          <Divider sx={{ width: '100%', borderStyle: 'hidden' }} />
          <div>
            <ReactGridLayout
              isDraggable={isDraggable}
              className='layout'
              layouts={{ xl: layoutLG, lg: layoutLG, md: layoutMD, sm: layoutMD, xs: layoutXXS, xxs: layoutXXS }}
              cols={{ xl: 12, lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 }}
              rowHeight={250}
              breakpoints={{ xl: 1500, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            >
              <Card sx={classes.card} key='repairType'>
                <CardHeader title='Repair Type' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(repairTypeData)}
                    title='Count of RMAs by Repair Type'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Repair Type'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='problemFound'>
                <CardHeader title='Cause of Problem Found' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(problemFoundData)}
                    title='Count of RMAs by Cause of Problem Found'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Cause of Problem Found'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='productType'>
                <CardHeader title='Product Type' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(productTypeData)}
                    title='Count of RMAs by Product Type'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Product Type'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='product'>
                <CardHeader title='Product' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(productData, productMap)}
                    title='Count of RMAs by Product'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Product'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='designator'>
                <CardHeader title='Designator' sx={classes.cardHeader} />
                <CustomSelect
                  id='designatorSelect'
                  style={{ width: '20%', marginTop: 15, marginLeft: 15 }}
                  label='View by...'
                  value={designatorByProduct}
                  onChange={e => setDesignatorByProduct(e.target.value)}
                  options={['Default (All)', 'Board Type', 'Product', 'Product Type']}
                  tooltip={<ConsolidatedTooltip />}
                />
                {(() => {
                  switch (designatorByProduct) {
                    case 'Board Type':
                      let perBoardData = designatorsByBoards(data)[designatorByBoardOption]
                        ? Object.values(designatorsByBoards(data)[designatorByBoardOption])
                        : []
                      const boardOptions = Array.from(
                        new Set(
                          boards(data).map(board => {
                            if (board.boardTypeName === '') {
                              return 'Not Set'
                            }
                            return board.boardTypeName
                          })
                        )
                      )
                      return (
                        <div>
                          <StatsSelect
                            style={{ width: '20%', marginTop: 15, marginLeft: 15 }}
                            label='Select board...'
                            value={designatorByBoardOption}
                            onChange={e => setDesignatorByBoardOption(e.target.value)}
                            options={boardOptions ? boardOptions : []}
                            height={250}
                          />

                          <div style={classes.graph}>
                            <StatsGraph
                              data={cleanAndSort(perBoardData)}
                              title='Count of RMAs by Designator'
                              yAxisLabel='RMA Count'
                              xAxisLabel='Designator'
                              autoResize={autoResize}
                            />
                          </div>
                        </div>
                      )

                    case 'Product':
                      let perProductData = designatorsByProduct(data)[designatorByProductOption]
                        ? Object.values(designatorsByProduct(data)[designatorByProductOption])
                        : []
                      let productOptions = Array.from(
                        new Set(
                          products.map(product => {
                            if (!product.isArchived) {
                              if (product.productName) {
                                return product.productName
                              }
                            }
                            return 'Not Set'
                          })
                        )
                      ).filter(element => {
                        return element
                      })
                      return (
                        <div>
                          <CustomSelect
                            id='productSelect'
                            style={{ width: '20%', marginTop: 15, marginLeft: 15 }}
                            label='Select product...'
                            value={designatorByProductOption}
                            onChange={e => setDesignatorByProductOption(e.target.value)}
                            options={productOptions}
                          />
                          <div style={classes.graph}>
                            <StatsGraph
                              data={cleanAndSort(perProductData)}
                              title='Count of RMAs by Designator'
                              yAxisLabel='RMA Count'
                              xAxisLabel='Designator'
                              autoResize={autoResize}
                            />
                          </div>
                        </div>
                      )

                    case 'Product Type':
                      let perProductTypeData = designatorsByProductType(data)[designatorByProductTypeOption]
                        ? Object.values(designatorsByProductType(data)[designatorByProductTypeOption])
                        : []
                      let productTypeOptions = Array.from(
                        new Set(
                          productTypes.map(productType => {
                            if (!productType.isArchived) {
                              if (productType.productTypeName) {
                                return productType.productTypeName
                              }
                            }
                            return 'Not Set'
                          })
                        )
                      )
                      return (
                        <div>
                          <CustomSelect
                            id='productTypeSelect'
                            style={{ width: '20%', marginTop: 15, marginLeft: 15 }}
                            label='Select product type...'
                            value={designatorByProductTypeOption}
                            onChange={e => setDesignatorByProductTypeOption(e.target.value)}
                            options={productTypeOptions}
                          />
                          <div style={classes.graph}>
                            <StatsGraph
                              data={cleanAndSort(perProductTypeData)}
                              title='Count of RMAs by Designator'
                              yAxisLabel='RMA Count'
                              xAxisLabel='Designator'
                              autoResize={autoResize}
                            />
                          </div>
                        </div>
                      )

                    default:
                      return (
                        <div style={classes.graph}>
                          <StatsGraph
                            data={cleanAndSort(DesignatorData)}
                            title='Count of RMAs by Designator'
                            yAxisLabel='RMA Count'
                            xAxisLabel='Designator'
                            autoResize={autoResize}
                          />
                        </div>
                      )
                  }
                })()}
              </Card>
              <Card sx={classes.card} key='boardType'>
                <CardHeader title='Board Type' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(boardTypeData)}
                    title='Count of RMAs by Board Type'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Board Type'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='customerComplaint'>
                <CardHeader title='Customer Complaint' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(customerComplaintData, customerComplaintMap)}
                    title='Count of RMAs by Customer Complaint'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Customer Complaint'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='finalDetermination'>
                <CardHeader title='Final Determination' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(finalDeterminationData)}
                    title='Count of RMAs by Final Determination'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Final Determination'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='customer'>
                <CardHeader title='Customer' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(customerData)}
                    title='Count of RMAs by Customer'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Customer'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='dealer'>
                <CardHeader title='Dealer' sx={classes.cardHeader} />
                <div style={classes.graph}>
                  <StatsGraph
                    data={cleanAndSort(dealerData)}
                    title='Count of RMAs by Dealer'
                    yAxisLabel='RMA Count'
                    xAxisLabel='Dealer'
                    autoResize={autoResize}
                    tooltip={<ConsolidatedTooltip />}
                  />
                </div>
              </Card>
              <Card sx={classes.card} key='daysOpen'>
                <CardHeader title='Days Open' sx={classes.cardHeader} />
                {/* <CustomSelect
        style={{ width: '40%', marginTop: 15, marginLeft: 15 }}
        label='Type'
        value={customerFilter}
        onChange={e => setCustomerFilter(e.target.value)}
        options={[
          'All Customers',
          ...(customers
            ? customers
                .filter(option => !option.isArchived)
                .map(option => option.customerName)
                .sort((a, b) => (b < a ? 1 : a < b ? -1 : 0))
            : [])
        ]}
      /> */}
                <div style={{ display: 'inline-block', margin: '24px' }}>Average days: {avgDaysOpen}</div>
                <div style={classes.graph}>
                  <StatsGraph
                    data={daysOpenData}
                    interval={4}
                    title={`Count of Days Open for ${
                      filter.customer.length === 0 ? 'All Customers' : filter.customer.map(c => c.customerName).join(', ')
                    }`}
                    yAxisLabel='RMA Count'
                    xAxisLabel='Days Open'
                    enableBrush={false}
                  />
                </div>
              </Card>

              <Card sx={classes.card} key='daysOpenByCustomer'>
                {CustomerList(filter, data)}
              </Card>
              <Card sx={classes.card} key='USAMap'>
                <CardHeader title='By State' sx={classes.cardHeader} />

                <div style={classes.graph}>
                  <StateMapGraph rmas={stateData} />
                </div>
              </Card>
              <Card sx={classes.card} key='allOptions'>
                <CardHeader title='All Options' sx={classes.cardHeader} />
                <CustomSelect
                  id='timedGraphOptionsSelect'
                  style={{ width: '40%', marginTop: 15, marginLeft: 15 }}
                  label='Type'
                  value={timedGraphDataOption}
                  onChange={e => setTimedGraphDataOption(e.target.value)}
                  options={allOptionsList ? Object.keys(allOptionsList) : []}
                />

                <div style={classes.graph}>
                  {timedGraphDataOption && timedGraphDataOption !== '' ? (
                    <StatsTimeGraph
                      data={allOptionsList[timedGraphDataOption] ? allOptionsList[timedGraphDataOption].data : []}
                      type={allOptionsList[timedGraphDataOption] ? allOptionsList[timedGraphDataOption].type : ''}
                      title={`${timedGraphDataOption} By Month`}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              </Card>
            </ReactGridLayout>
          </div>
        </div>
      }
    />
  )
}
