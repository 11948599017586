import { MenuAppBar, NotFoundPage } from '@wavetronix/common-components'
import { useMsal } from '@azure/msal-react'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import ListOptionsApi from '../api/ListOptionsApi'
import { env } from '../index.js'
import RmaDetailView from './RmaDetailView'
import RmaListPage from './RmaListPage'
import RmaStatistics from './RmaStatistics'
import ListManager from './ListManager'
import { useState } from 'react'
import { DEFAULT_RMA_FILTER } from './drawers/RmaFilterDrawer'
import { DEFAULT_RMASTATS_FILTER } from './drawers/RmaStatsFilterDrawer'
import version from '../env/version.json'
export default function PageRouter() {
  const { instance, accounts } = useMsal()
  const [filter, setFilter] = useState(DEFAULT_RMA_FILTER)
  const [statsFilter, setStatsFilter] = useState(DEFAULT_RMASTATS_FILTER)
  const {
    data: boardTypes,
    isLoading: boardTypesLoading,
    refetch: boardTypesRefetch
  } = useQuery({
    queryKey: ['boardTypes'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'boardType')
  })
  const {
    data: problemsFound,
    isLoading: problemsFoundLoading,
    refetch: problemsFoundRefetch
  } = useQuery({
    queryKey: ['problemsFound'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'problemsFound')
  })
  const {
    data: issues,
    isLoading: issuesLoading,
    refetch: issuesRefetch
  } = useQuery({ queryKey: ['issues'], queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'issue') })
  const {
    data: initials,
    isLoading: initialsLoading,
    refetch: initialsRefetch
  } = useQuery({
    queryKey: ['initials'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'initial')
  })
  const {
    data: customerComplaints,
    isLoading: customerComplaintsLoading,
    refetch: customerComplaintsRefetch
  } = useQuery({
    queryKey: ['customerComplaints'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'customerComplaint')
  })
  const {
    data: repairComments,
    isLoading: repairCommentsLoading,
    refetch: repairCommentsRefetch
  } = useQuery({
    queryKey: ['repairComments'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'repairComments')
  })
  const {
    data: repairTypes,
    isLoading: repairTypesLoading,
    refetch: repairTypesRefetch
  } = useQuery({
    queryKey: ['RepairTypes'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'repairtype')
  })

  const {
    data: dealers,
    isLoading: dealerLoading,
    refetch: dealerRefetch
  } = useQuery({ queryKey: ['Dealers'], queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'dealer') })

  const {
    data: productTypes,
    isLoading: productTypesLoading,
    refetch: productTypesRefetch
  } = useQuery({
    queryKey: ['ProductTypes'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'productType')
  })

  const {
    data: products,
    isLoading: productsLoading,
    refetch: productsRefetch
  } = useQuery({
    queryKey: ['Products'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'product')
  })

  const {
    data: customers,
    isLoading: customersLoading,
    refetch: customersRefetch
  } = useQuery({
    queryKey: ['Customers'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'customer')
  })

  const {
    data: states,
    isLoading: statesLoading,
    refetch: statesRefetch
  } = useQuery({ queryKey: ['States'], queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'state') })

  const {
    data: countries,
    isLoading: countriesLoading,
    refetch: countriesRefetch
  } = useQuery({
    queryKey: ['Countries'],
    queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'country')
  })

  const {
    data: cities,
    isLoading: citiesLoading,
    refetch: citiesRefetch
  } = useQuery({ queryKey: ['Cities'], queryFn: async () => await ListOptionsApi.getListOptions(instance, accounts, 'City') })

  return (
    <>
      <MenuAppBar env={env} appHeader='RMA' />
      <BrowserRouter>
        <Switch>
          <Route path='/version' exact render={() => <div>{version.version}</div>} />

          <Route
            path='/'
            exact
            render={() => (
              <RmaListPage
                filter={filter}
                setFilter={setFilter}
                boardTypes={boardTypes}
                boardTypesLoading={boardTypesLoading}
                boardTypesRefetch={boardTypesRefetch}
                problemsFound={problemsFound}
                problemsFoundLoading={problemsFoundLoading}
                problemsFoundRefetch={problemsFoundRefetch}
                issues={issues}
                issuesLoading={issuesLoading}
                issuesRefetch={issuesRefetch}
                initials={initials}
                initialsLoading={initialsLoading}
                initialsRefetch={initialsRefetch}
                customerComplaints={customerComplaints}
                customerComplaintsLoading={customerComplaintsLoading}
                customerComplaintsRefetch={customerComplaintsRefetch}
                finalDeterminations={customerComplaints}
                finalDeterminationsLoading={customerComplaintsLoading}
                finalDeterminationsRefetch={customerComplaintsRefetch}
                repairComments={repairComments}
                repairCommentsLoading={repairCommentsLoading}
                repairCommentsRefetch={repairCommentsRefetch}
                repairTypes={repairTypes}
                repairTypesLoading={repairTypesLoading}
                repairTypesRefetch={repairTypesRefetch}
                dealers={dealers}
                dealerLoading={dealerLoading}
                dealerRefetch={dealerRefetch}
                productTypes={productTypes}
                productTypesLoading={productTypesLoading}
                productTypesRefetch={productTypesRefetch}
                products={products}
                productsLoading={productsLoading}
                productsRefetch={productsRefetch}
                customers={customers}
                customersLoading={customersLoading}
                customersRefetch={customersRefetch}
                states={states}
                statesLoading={statesLoading}
                statesRefetch={statesRefetch}
                countries={countries}
                countriesLoading={countriesLoading}
                countriesRefetch={countriesRefetch}
                cities={cities}
                citiesLoading={citiesLoading}
                citiesRefetch={citiesRefetch}
              />
            )}
          />
          <Route
            path='/rma'
            exact
            render={() => (
              <RmaDetailView
                boardTypes={boardTypes}
                boardTypesLoading={boardTypesLoading}
                boardTypesRefetch={boardTypesRefetch}
                problemsFound={problemsFound}
                problemsFoundLoading={problemsFoundLoading}
                problemsFoundRefetch={problemsFoundRefetch}
                issues={issues}
                issuesLoading={issuesLoading}
                issuesRefetch={issuesRefetch}
                initials={initials}
                initialsLoading={initialsLoading}
                initialsRefetch={initialsRefetch}
                customerComplaints={customerComplaints}
                customerComplaintsLoading={customerComplaintsLoading}
                customerComplaintsRefetch={customerComplaintsRefetch}
                finalDeterminations={customerComplaints}
                finalDeterminationsLoading={customerComplaintsLoading}
                finalDeterminationsRefetch={customerComplaintsRefetch}
                repairComments={repairComments}
                repairCommentsLoading={repairCommentsLoading}
                repairCommentsRefetch={repairCommentsRefetch}
                repairTypes={repairTypes}
                repairTypesLoading={repairTypesLoading}
                repairTypesRefetch={repairTypesRefetch}
                dealers={dealers}
                dealerLoading={dealerLoading}
                dealerRefetch={dealerRefetch}
                productTypes={productTypes}
                productTypesLoading={productTypesLoading}
                productTypesRefetch={productTypesRefetch}
                products={products}
                productsLoading={productsLoading}
                productsRefetch={productsRefetch}
                customers={customers}
                customersLoading={customersLoading}
                customersRefetch={customersRefetch}
                states={states}
                statesLoading={statesLoading}
                statesRefetch={statesRefetch}
                countries={countries}
                countriesLoading={countriesLoading}
                countriesRefetch={countriesRefetch}
                cities={cities}
                citiesLoading={citiesLoading}
                citiesRefetch={citiesRefetch}
              />
            )}
          />
          <Route
            path='/stats'
            exact
            render={() => (
              <RmaStatistics
                boardTypes={boardTypes ? boardTypes.map(b => b.boardTypeName) : []}
                problemsFound={problemsFound ? problemsFound.map(b => b.problemsFoundName) : []}
                issues={issues ? issues.map(b => b.issueName) : []}
                customerComplaints={customerComplaints ? customerComplaints.map(b => b.customerComplaintName) : []}
                repairTypes={repairTypes ? repairTypes.map(b => b.repairTypeName) : []}
                productTypes={productTypes}
                products={products}
                customers={customers}
                dealers={dealers}
                states={states ? states.map(c => c) : []}
                filter={statsFilter}
                setFilter={setStatsFilter}
              />
            )}
          />
          <Route
            path='/lists'
            exact
            render={() => (
              <ListManager
                boardTypes={boardTypes}
                boardTypesLoading={boardTypesLoading}
                boardTypesRefetch={boardTypesRefetch}
                problemsFound={problemsFound}
                problemsFoundLoading={problemsFoundLoading}
                problemsFoundRefetch={problemsFoundRefetch}
                issues={issues}
                issuesLoading={issuesLoading}
                issuesRefetch={issuesRefetch}
                initials={initials}
                initialsLoading={initialsLoading}
                initialsRefetch={initialsRefetch}
                customerComplaints={customerComplaints}
                customerComplaintsLoading={customerComplaintsLoading}
                customerComplaintsRefetch={customerComplaintsRefetch}
                finalDeterminations={customerComplaints}
                finalDeterminationsLoading={customerComplaintsLoading}
                finalDeterminationsRefetch={customerComplaintsRefetch}
                repairComments={repairComments}
                repairCommentsLoading={repairCommentsLoading}
                repairCommentsRefetch={repairCommentsRefetch}
                repairTypes={repairTypes}
                repairTypesLoading={repairTypesLoading}
                repairTypesRefetch={repairTypesRefetch}
                dealers={dealers}
                dealerLoading={dealerLoading}
                dealerRefetch={dealerRefetch}
                productTypes={productTypes}
                productTypesLoading={productTypesLoading}
                productTypesRefetch={productTypesRefetch}
                products={products}
                productsLoading={productsLoading}
                productsRefetch={productsRefetch}
                customers={customers}
                customersLoading={customersLoading}
                customersRefetch={customersRefetch}
                states={states}
                statesLoading={statesLoading}
                statesRefetch={statesRefetch}
                countries={countries}
                countriesLoading={countriesLoading}
                countriesRefetch={countriesRefetch}
                cities={cities}
                citiesLoading={citiesLoading}
                citiesRefetch={citiesRefetch}
              />
            )}
          />
          {/* This wildcard route needs to stay at the bottom */}
          <Route path='/*' component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </>
  )
}
