import { useMsal } from '@azure/msal-react'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Divider, Menu, MenuItem } from '@mui/material'
import { CustomDataGrid, downloadBlob, HasAccess, RegularButton, PersistantFilterDiv } from '@wavetronix/common-components'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import RmaApi from '../api/RmaApi'
import RmaFilterDrawer, { DEFAULT_RMA_FILTER, filterRmas } from './drawers/RmaFilterDrawer'
import NewRmaModal from './NewRmaModal'
import { env } from '../index.js'
import dayjs from 'dayjs'
import Papa from 'papaparse'

export const columns = [
  { headerName: 'RMA Number', field: 'rmaNumber', flex: 2 },
  { headerName: 'Dealer', field: 'dealer', flex: 2 },
  { headerName: 'Customer', field: 'customer', flex: 2 },
  { headerName: 'Product', field: 'product', flex: 1 },
  { headerName: 'Serial Number', field: 'serialNumber', flex: 2 },
  { headerName: 'Repair Type', field: 'repairType', flex: 2 },
  { headerName: 'Open Date', field: 'openDate', flex: 1, valueGetter: date => dayjs(date.value).format('MM/DD/YYYY') },
  {
    headerName: 'Completed Date',
    field: 'completedDate',
    flex: 2,
    valueGetter: date => dayjs(date.value).format('MM/DD/YYYY'),
    hide: true
  },
  {
    headerName: 'Original Ship Date',
    field: 'originalShipDate',
    flex: 2,
    hide: true,
    valueGetter: date => dayjs(date.value).format('MM/DD/YYYY')
  },
  {
    headerName: 'Designators',
    field: 'designator',
    flex: 2,
    renderCell: rma => {
      return <div>{rma.row.boardType.map(board => board.designators.map(des => des)).toString()}</div>
    }
  },
  {
    headerName: 'Board Type',
    field: 'boardType',
    flex: 2,
    renderCell: rma => {
      return <div>{rma.row.boardType.map(board => board.boardTypeName).toString()}</div>
    },
    hide: false
  },
  { headerName: 'City', field: 'city', flex: 1, hide: true },
  { headerName: 'State', field: 'state', flex: 1, hide: true },
  { headerName: 'Country', field: 'country', flex: 1, hide: true },
  { headerName: 'Product Type', field: 'productType', flex: 1, hide: true },
  { headerName: 'Cause of Problem Found', field: 'problemFound', flex: 2, hide: true },
  { headerName: 'Customer Complaint', field: 'customerComplaint', flex: 2, hide: true },
  { headerName: 'Final Determination', field: 'finalDetermination', flex: 2, hide: true },
  { headerName: 'Amount Spent', field: 'amountSpent', flex: 1, hide: true },
  { headerName: 'Amount Waived', field: 'amountWaived', flex: 1, hide: true },
  { headerName: 'Days Open', field: 'daysOpen', flex: 1, hide: true },
  { headerName: 'Repair Tech', field: 'initials', flex: 1, hide: true },
  { headerName: 'Rapid Repair', field: 'isRapidRepair', flex: 1, hide: true },
  { headerName: 'Out of Box Failure', field: 'outOfBoxFailure', flex: 1, hide: true }
]

export default function RmaListPage({
  filter,
  setFilter,
  repairTypes,
  repairTypesLoading,
  repairTypesRefetch,
  dealers,
  dealerLoading,
  dealerRefetch,
  productTypes,
  productTypesLoading,
  productTypesRefetch,
  products,
  productsLoading,
  productsRefetch,
  boardTypes,
  boardTypesLoading,
  boardTypesRefetch,
  problemsFound,
  problemsFoundLoading,
  problemsFoundRefetch,
  issues,
  issuesLoading,
  issuesRefetch,
  initials,
  initialsLoading,
  initialsRefetch,
  customerComplaints,
  customerComplaintsLoading,
  customerComplaintsRefetch,
  repairComments,
  repairCommentsLoading,
  repairCommentsRefetch,
  customers,
  customersLoading,
  customersRefetch,
  states,
  statesLoading,
  statesRefetch,
  countries,
  countriesLoading,
  countriesRefetch,
  cities,
  citiesLoading,
  citiesRefetch
}) {
  const [newRmaModalOpen, setNewRmaModalOpen] = useState(false)
  const { instance, accounts } = useMsal()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = {
    menuItem: {
      cursor: 'pointer',
      padding: '6px 64px 6px 14px',
      fontSize: '16px',
      fontFamily: 'Arial',
      '&:hover': {
        backgroundColor: '#f5f5f5'
      }
    },
    divider: {
      color: '#676867',
      fontSize: '14px'
    }
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['RMA Requests'],
    queryFn: async () => await RmaApi.getRmas(instance, accounts)
  })

  const flattenBoardTypeDataToString = data => {
    return data.map(rma => ({
      ...rma,
      boardType: rma.boardType
        .map(bType => {
          let boardInfo = `${bType.boardTypeName}`

          let desList = bType.designators.join(',')

          if (desList && desList !== '') {
            boardInfo += `(${desList})`
          }

          return boardInfo
        })
        .join('. ')
    }))
  }

  function exportCsv() {
    if (data) {
      let flatData = flattenBoardTypeDataToString(data)
      const csvContent = Papa.unparse(flatData)

      downloadBlob(csvContent, 'RMA.csv', 'text/csv;charset=utf-8;')
    }
  }
  return (
    <>
      <NewRmaModal
        refetch={refetch}
        open={newRmaModalOpen}
        onClose={() => setNewRmaModalOpen(false)}
        boardTypes={boardTypes}
        boardTypesLoading={boardTypesLoading}
        boardTypesRefetch={boardTypesRefetch}
        problemsFound={problemsFound}
        problemsFoundLoading={problemsFoundLoading}
        problemsFoundRefetch={problemsFoundRefetch}
        issues={issues}
        issuesLoading={issuesLoading}
        issuesRefetch={issuesRefetch}
        initials={initials}
        initialsLoading={initialsLoading}
        initialsRefetch={initialsRefetch}
        customerComplaints={customerComplaints}
        customerComplaintsLoading={customerComplaintsLoading}
        customerComplaintsRefetch={customerComplaintsRefetch}
        repairComments={repairComments}
        repairCommentsLoading={repairCommentsLoading}
        repairCommentsRefetch={repairCommentsRefetch}
        repairTypes={repairTypes}
        repairTypesLoading={repairTypesLoading}
        repairTypesRefetch={repairTypesRefetch}
        dealers={dealers}
        dealerLoading={dealerLoading}
        dealerRefetch={dealerRefetch}
        productTypes={productTypes}
        productTypesLoading={productTypesLoading}
        productTypesRefetch={productTypesRefetch}
        products={products}
        productsLoading={productsLoading}
        productsRefetch={productsRefetch}
        customers={customers}
        customersLoading={customersLoading}
        customersRefetch={customersRefetch}
        states={states}
        statesLoading={statesLoading}
        statesRefetch={statesRefetch}
        countries={countries}
        countriesLoading={countriesLoading}
        countriesRefetch={countriesRefetch}
        cities={cities}
        citiesLoading={citiesLoading}
        citiesRefetch={citiesRefetch}
      />
      <PersistantFilterDiv
        resetFilter={() => setFilter(DEFAULT_RMA_FILTER)}
        drawer={
          <RmaFilterDrawer
            filter={filter}
            setFilter={setFilter}
            dealers={dealers}
            productTypes={productTypes}
            boardTypes={boardTypes}
            products={products}
            customers={customers}
          />
        }
        page={
          <div>
            <div style={{ marginLeft: '24px', display: 'flex', justifyContent: 'space-between' }}>
              <RegularButton
                id='optionsButton'
                onClick={handleClick}
                endIcon={<KeyboardArrowDown />}
                style={{ height: '40.5px', marginTop: '16px' }}
              >
                Options
              </RegularButton>
              <Menu open={openMenu} id='basic-menu' anchorEl={anchorEl} onClose={handleClose} disableScrollLock={true}>
                <Divider textAlign='left' sx={{ ...classes.divider, marginBottom: '8px' }} variant='fullWidth'>
                  Reports
                </Divider>
                <HasAccess allowedRoles={['RMA User']} env={env}>
                  <MenuItem id='newRmaMenuItem' onClick={() => setNewRmaModalOpen(true)} sx={classes.menuItem}>
                    New RMA
                  </MenuItem>
                </HasAccess>
                <MenuItem id='viewStatsMenuItem' onClick={() => history.push(`/stats`)} sx={classes.menuItem}>
                  View Stats
                </MenuItem>
                <HasAccess allowedRoles={['RMA User']} env={env}>
                  <Divider textAlign='left' sx={{ ...classes.divider, marginBottom: '8px' }} variant='fullWidth'>
                    Manage
                  </Divider>
                  <MenuItem id='editListsMenuItem' onClick={() => history.push('/lists')} sx={classes.menuItem}>
                    Edit Lists
                  </MenuItem>
                </HasAccess>
                <Divider textAlign='left' sx={{ ...classes.divider, marginBottom: '8px' }} variant='fullWidth'>
                  Export
                </Divider>
                <MenuItem id='exportCSVMenuItem' onClick={exportCsv} sx={classes.menuItem}>
                  Export CSV
                </MenuItem>
              </Menu>
            </div>
            <div style={{ padding: '24px 0px 0px 24px' }}>
              <CustomDataGrid
                onRowClick={rma => history.push(`/rma?id=${rma.id}`)}
                columns={columns}
                rows={data ? filterRmas(filter, data) : []}
                loading={isLoading}
                cursor='pointer'
              />
            </div>
          </div>
        }
      />
    </>
  )
}
