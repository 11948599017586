import { WtxColors } from '@wavetronix/common-components'
import React from 'react'
import { useState } from 'react'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import StatsRmaModal from '../modals/StatsRmaModal'

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

export default function StateMapGraph({ rmas }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [stateRmas, setStateRmas] = useState([])
  const handleClick = geo => {
    setStateRmas(geo ? geo.rmas : [])
    setModalOpen(true)
  }
  const max = rmas ? Math.max(...rmas.map(rma => rma.count)) : 0
  const HEX_SCALAR = 255
  return (
    <>
      <StatsRmaModal open={modalOpen} onClose={() => setModalOpen(false)} rmas={stateRmas} />
      <ComposableMap projection='geoAlbersUsa'>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const cur = rmas ? rmas.find(s => s.fullName === geo.properties.name) : null
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={`${WtxColors.IQ_BLUE}${cur ? Math.floor(cur.count * HEX_SCALAR / max).toString(16).padStart(2, '0') : '01'}`}
                  stroke={WtxColors.CONCRETE}
                  style={{
                    hover: { fill: '#04D' },
                    pressed: { fill: '#02A' }
                  }}
                  onClick={() => handleClick(cur)}
                />
              )
            })
          }
        </Geographies>
      </ComposableMap>
    </>
  )
}
