export const localEnv = {
  runMode: 'dev',
  clientId: '8041487f-9408-4310-b75d-d84d809d7ec8',
  urls: {
    rmaURL: 'https://wtx-rma-rest.wtxdev.com'
  },
  basicAuthentication: {}
}

// export const localEnv = {
//   runMode: 'prod',
//   clientId: 'c792f09a-05e8-407d-80a8-f2336d5c7020',
//   urls: {
//     rmaURL: 'https://wtx-rma-rest.wavetronix.com'
//   },
//   basicAuthentication: {}
// }
