import { useMsal } from '@azure/msal-react'
import { AddCircleOutline } from '@mui/icons-material'
import {
  Autocomplete,
  Chip,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import {
  CustomAccordion,
  PrimaryButton,
  RegularButton,
  SlideUpDialog,
  SnackbarVariants,
  WtxColors,
  CenteredDiv
} from '@wavetronix/common-components'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { DEFAULT_FORM } from '../ListOptions'
import ListOptionsApi from '../api/ListOptionsApi'
import RmaApi from '../api/RmaApi'

const classes = {
  formEntry: {
    margin: '10px',
    width: '205px'
  },
  accordionForm: {
    marginLeft: '10px',
    width: '205px'
  }
}

export default function NewRmaModal({
  open,
  onClose,
  refetch,
  repairTypes,
  repairTypesLoading,
  repairTypesRefetch,
  dealers,
  dealerLoading,
  dealerRefetch,
  productTypes,
  productTypesLoading,
  productTypesRefetch,
  products,
  productsLoading,
  productsRefetch,
  boardTypes,
  boardTypesLoading,
  boardTypesRefetch,
  problemsFound,
  problemsFoundLoading,
  problemsFoundRefetch,
  initials,
  initialsLoading,
  initialsRefetch,
  customerComplaints,
  customerComplaintsLoading,
  customerComplaintsRefetch,
  customers,
  customersLoading,
  customersRefetch,
  states,
  statesLoading,
  statesRefetch,
  countries,
  countriesLoading,
  countriesRefetch,
  cities,
  citiesLoading,
  citiesRefetch
}) {
  const { instance, accounts } = useMsal()
  const [form, setForm] = useState(DEFAULT_FORM)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [dealerOrCustomer, setDealerOrCustomer] = useState(false)
  const [submittedProducts, setSubmittedProducts] = useState([])
  const [newDesignator, setNewDesignator] = useState('')
  const [expanded, setExpanded] = useState(false)
  const [dataIsValid, setDataIsValid] = useState(false)
  const [dealerIsValid, setDealerIsValid] = useState(false)
  const [customerIsValid, setCustomerIsValid] = useState(false)
  const [stateIsValid, setStateIsValid] = useState(false)
  const [countryIsValid, setCountryIsValid] = useState(false)
  const [productTypeIsValid, setProductTypeIsValid] = useState(false)
  const [productIsValid, setProductIsValid] = useState(false)
  const [repairTypeIsValid, setRepairTypeIsValid] = useState(false)
  const [cityIsValid, setCityIsValid] = useState(false)
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(false)
  const [rmaNumberIsValid, setRmaNumberIsValid] = useState(false)
  const [openDateIsValid, setOpenDateIsValid] = useState(false)
  const dealerError = dealerIsValid && isValidOrEmpty(form.customer, customers, 'customerName')
  const customerError = customerIsValid && isValidOrEmpty(form.dealer, dealers, 'dealerName')

  useEffect(() => {
    setDataIsValid(
      (dealerError || customerError) &&
        stateIsValid &&
        countryIsValid &&
        productTypeIsValid &&
        productIsValid &&
        repairTypeIsValid &&
        cityIsValid &&
        serialNumberIsValid &&
        rmaNumberIsValid &&
        openDateIsValid
    )
  }, [
    dealerError,
    customerError,
    stateIsValid,
    countryIsValid,
    productTypeIsValid,
    productIsValid,
    repairTypeIsValid,
    cityIsValid,
    serialNumberIsValid,
    rmaNumberIsValid,
    openDateIsValid
  ])

  useEffect(() => {
    if (form.customer !== '' || form.dealer !== '') {
      setDealerOrCustomer(true)
    } else {
      setDealerOrCustomer(false)
    }
  }, [form.dealer, form.customer])

  async function updateDesignatorList(item, optionList, refetch) {
    let key = enqueueSnackbar(`Adding New Designator...`, SnackbarVariants.LOADING)
    await ListOptionsApi.archiveListOption(instance, accounts, item, optionList)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Added New Designator`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add New Designator`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }
  async function createNewBoardDropOption(OptionEntry, ListType, refetch) {
    let key = enqueueSnackbar(`Saving ${ListType}...`, SnackbarVariants.LOADING)
    await ListOptionsApi.addNewDropOption(instance, accounts, OptionEntry, ListType)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${ListType} was Added`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${ListType}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  function clearRma() {
    setForm(f => ({
      ...f,
      rmaNumber: '',
      openDate: null,
      dealer: '',
      customer: '',
      city: '',
      state: '',
      country: '',
      productType: '',
      product: '',
      serialNumber: '',
      originalShipDate: null,
      entryNumber: 0,
      boardType: [{ boardTypeName: '', designators: [] }],
      boardRev: '',
      problemFound: '',
      issue: '',
      designator: '',
      replacedWithHittite: '',
      repairComments: '',
      completedDate: dayjs(),
      initials: '',
      daysOpen: '',
      customerComplaint: '',
      repairType: '',
      outOfBoxFailure: '',
      buildDate: null,
      amountSpent: 0,
      isRapidRepair: false,
      amountWaived: 0,
      finalDetermination: ''
    }))
    setSubmittedProducts([])
  }

  function closeModal() {
    clearRma()
    onClose()
  }

  function addAnotherProduct() {
    handleSubmit()
    clearProductSpecificInfo()
  }

  function clearProductSpecificInfo() {
    setForm(f => ({
      ...f,
      productType: '',
      product: '',
      serialNumber: '',
      originalShipDate: null,
      boardType: [{ boardTypeName: '', designators: [] }],
      boardRev: '',
      problemFound: '',
      issue: '',
      designator: '',
      replacedWithHittite: '',
      repairComments: '',
      initials: '',
      customerComplaint: '',
      repairType: '',
      outOfBoxFailure: '',
      buildDate: null,
      amountSpent: 0,
      isRapidRepair: false,
      amountWaived: 0,
      finalDetermination: ''
    }))
  }

  const submitForm = event => {
    if (event === 'addAnother') {
      addAnotherProduct()
    }
    if (event === 'submit') {
      handleSubmit()
      closeModal()
    }
  }

  function isValidOrEmpty(value, options, fieldName) {
    if (value === '' || validateStrict(value, options, fieldName)) {
      return true
    } else return false
  }

  function validateStrict(value, options, fieldName) {
    let existingOption = false

    for (let i = 0; i < options.length; i++) {
      if (options[i][fieldName] === value) {
        if (options[i]['isArchived']) {
        } else {
          existingOption = true
        }
      }
    }

    return existingOption
  }

  const handleSubmit = event => {
    let existingCustomerComplaint = false
    let existingInitials = false
    let existingCity = false
    let existingBoardType = false
    let existingDesignator = false

    for (let i = 0; i < initials.length; i++) {
      if (initials[i].initialName === form.initials) existingInitials = true
    }
    for (let i = 0; i < customerComplaints.length; i++) {
      if (customerComplaints[i].customerComplaintName === form.customerComplaint) existingCustomerComplaint = true
    }
    for (let i = 0; i < cities.length; i++) {
      if (cities[i].cityName === form.city) existingCity = true
    }
    for (let board of form.boardType) {
      for (let existingBoard of boardTypes) {
        if (board.boardTypeName === existingBoard.boardTypeName) {
          existingBoardType = true
          for (let designator of board.designators) {
            for (let existingDes of existingBoard.designators) {
              existingDesignator = false
              if (designator === existingDes) {
                existingDesignator = true
              }
            }
            if (!existingDesignator && designator !== '' && board) {
              existingBoard.designators.push(designator)
              updateDesignatorList(existingBoard, 'boardType', boardTypesRefetch)
            }
          }
        }
      }
      if (!existingBoardType && board.boardTypeName !== '' && board) {
        createNewBoardDropOption(board, 'BoardType', boardTypesRefetch)
      }
    }

    if (!existingCity && form.city !== '' && form.city) createNewDropOption(form.city, 'City', citiesRefetch)
    if (!existingInitials && form.initials !== '' && form.initials) createNewDropOption(form.initials, 'Initial', initialsRefetch)
    if (!existingCustomerComplaint && form.customerComplaint !== '' && form.customerComplaint)
      createNewDropOption(form.customerComplaint, 'CustomerComplaint', customerComplaintsRefetch)

    if (dataIsValid) submitRma()
    else enqueueSnackbar('Could not create RMA due to invalid data.', SnackbarVariants.ERROR)
  }

  async function createNewDropOption(OptionEntry, ListType, refetch) {
    let key = enqueueSnackbar(`Saving ${ListType}...`, SnackbarVariants.LOADING)
    await ListOptionsApi.addNewDropOption(instance, accounts, { [`${ListType}Name`]: OptionEntry }, ListType)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${ListType} was Added`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${ListType}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  async function submitRma() {
    let key = enqueueSnackbar('Saving RMA...', SnackbarVariants.LOADING)
    await RmaApi.submitRma(instance, accounts, form)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar('New RMA was created', SnackbarVariants.SUCCESS)
        setSubmittedProducts([...submittedProducts, form.serialNumber])
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to create RMA', SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  const openAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  function addBoard() {
    let previousBoardList = form.boardType
    previousBoardList.push({ boardTypeName: '', designators: [] })
    setForm(f => ({ ...f, boardType: previousBoardList }))
  }
  function removeBoard(board) {
    let previousBoardList = form.boardType
    previousBoardList.splice(board, 1)
    setForm(f => ({ ...f, boardType: previousBoardList }))
  }
  function setBoardType(e, board) {
    let previousBoardList = form.boardType
    previousBoardList[board].boardTypeName = e.replace(/\b\w/g, m => m.toUpperCase())
    setForm(f => ({ ...f, boardType: previousBoardList }))
  }
  function setDesignator(e, board) {
    let previousBoardList = form.boardType
    if (e !== '') {
      previousBoardList[board].designators.push(e.toUpperCase())
    }
    setForm(f => ({ ...f, boardType: previousBoardList }))
    setNewDesignator('')
  }
  function deleteDesignator(board, designator) {
    let previousBoardList = form.boardType
    previousBoardList[board].designators.splice(designator, 1)
    setForm(f => ({ ...f, boardType: previousBoardList }))
  }

  return (
    <>
      <SlideUpDialog
        id='newRmaModal'
        open={open}
        onClose={closeModal}
        maxWidth={'xl'}
        fullWidth={true}
        title={<h3 style={{ margin: 0 }}>New RMA</h3>}
        actions={
          <>
            <RegularButton id='newRmaCancelButton' onClick={closeModal} style={{ border: '2px solid red', color: 'red' }}>
              Cancel
            </RegularButton>
            <RegularButton id='newRmaAddButton' onClick={() => submitForm('addAnother')} disabled={!dataIsValid}>
              Add Another
            </RegularButton>
            <PrimaryButton id='newRmaSubmitButton' onClick={() => submitForm('submit')} disabled={!dataIsValid}>
              Submit
            </PrimaryButton>
          </>
        }
      >
        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={{ md: 1, lg: 2 }}>
            <Grid item md={12} lg={9}>
              <CustomAccordion
                defaultExpanded
                title={<h3>General info</h3>}
                detailStyle={{ marginTop: '10px' }}
                titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                children={
                  <>
                    <TextField
                      id='rmaNumberTextField'
                      size='small'
                      required
                      type='number'
                      onChange={e => {
                        setForm(f => ({ ...f, rmaNumber: e.target.value }))
                        setRmaNumberIsValid(e.target.value !== '')
                      }}
                      value={form.rmaNumber}
                      variant='outlined'
                      label='RMA Number'
                      style={{ ...classes.formEntry }}
                    />
                    <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                      <DatePicker
                        id='rmaOpenDatePicker'
                        label='Open Date*'
                        inputFormat='MM/DD/YYYY'
                        value={form.openDate}
                        onChange={date => {
                          setForm(f => ({ ...f, openDate: date }))
                          console.log(date ? true : false)
                          setOpenDateIsValid(date ? true : false)
                        }}
                        renderInput={params => <TextField size='small' {...params} />}
                      />
                    </div>
                    <Autocomplete
                      id='dealerNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, display: 'inline-block' }}
                      options={
                        dealers
                          ? dealers
                              .filter(option => !option.isArchived)
                              .map(dealer => dealer.dealerName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.dealer, dealers, 'dealerName')}
                          required={!dealerOrCustomer}
                          label='Dealer'
                          variant='outlined'
                        />
                      )}
                      value={form.dealer}
                      loading={dealerLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, dealer: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
                        setDealerIsValid(validateStrict(e, dealers, 'dealerName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <Autocomplete
                      id='customerNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                      options={
                        customers
                          ? customers
                              .filter(option => !option.isArchived)
                              .map(customer => customer.customerName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.customer, customers, 'customerName')}
                          required={!dealerOrCustomer}
                          label='Customer'
                          variant='outlined'
                        />
                      )}
                      value={form.customer}
                      loading={customersLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, customer: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
                        setCustomerIsValid(validateStrict(e, customers, 'customerName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <Autocomplete
                      id='cityNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                      options={
                        cities
                          ? cities
                              .filter(option => !option.isArchived)
                              .map(city => city.cityName)
                              .sort()
                          : []
                      }
                      renderInput={params => <TextField size='small' {...params} required label='City' variant='outlined' />}
                      value={form.city}
                      loading={citiesLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, city: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
                        setCityIsValid(e !== '')
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                      freeSolo={true}
                    />
                    <Autocomplete
                      id='stateNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                      options={
                        states
                          ? states
                              .filter(option => !option.isArchived)
                              .map(state => state.stateName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.state, states, 'stateName')}
                          required
                          label='State'
                          variant='outlined'
                        />
                      )}
                      value={form.state}
                      loading={statesLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, state: e.toUpperCase().replace(',', ' -') }))
                        setStateIsValid(validateStrict(e, states, 'stateName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <Autocomplete
                      id='countryNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                      options={
                        countries
                          ? countries
                              .filter(option => !option.isArchived)
                              .map(country => country.countryName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.country, countries, 'countryName')}
                          required
                          label='Country'
                          variant='outlined'
                        />
                      )}
                      value={form.country}
                      loading={countriesLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, country: e.toUpperCase().replace(',', ' -') }))
                        setCountryIsValid(validateStrict(e, countries, 'countryName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                  </>
                }
              />

              <CustomAccordion
                defaultExpanded
                title={<h3>Product info</h3>}
                titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                detailStyle={{ marginTop: '10px' }}
                children={
                  <>
                    <Autocomplete
                      id='productTypeNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, display: 'inline-block' }}
                      options={
                        productTypes
                          ? productTypes
                              .filter(option => !option.isArchived)
                              .map(productType => productType.productTypeName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.productType, productTypes, 'productTypeName')}
                          required
                          label='Product Type'
                          variant='outlined'
                        />
                      )}
                      value={form.productType}
                      loading={productTypesLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, productType: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
                        setProductTypeIsValid(validateStrict(e, productTypes, 'productTypeName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <Autocomplete
                      id='productNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, display: 'inline-block' }}
                      options={
                        products
                          ? products
                              .filter(option => !option.isArchived)
                              .map(product => product.productName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.product, products, 'productName')}
                          required
                          label='Product'
                          variant='outlined'
                        />
                      )}
                      value={form.product}
                      loading={productsLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, product: e.toUpperCase().replace(',', ' -') }))
                        setProductIsValid(validateStrict(e, products, 'productName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <TextField
                      id='serialNumberTextField'
                      size='small'
                      required
                      onChange={e => {
                        setForm(f => ({ ...f, serialNumber: e.target.value.toUpperCase().replace(' ', '-').replace(',', '-') }))
                        setSerialNumberIsValid(e !== '')
                      }}
                      value={form.serialNumber}
                      variant='outlined'
                      label='Serial Number'
                      style={{ ...classes.formEntry, width: '205px' }}
                    />
                    <Autocomplete
                      id='repairTypeNameAutocomplete'
                      size='small'
                      style={{ ...classes.formEntry, display: 'inline-block' }}
                      options={
                        repairTypes
                          ? repairTypes
                              .filter(option => !option.isArchived)
                              .map(repairType => repairType.repairTypeName)
                              .sort()
                          : []
                      }
                      renderInput={params => (
                        <TextField
                          size='small'
                          {...params}
                          error={!isValidOrEmpty(form.repairType, repairTypes, 'repairTypeName')}
                          required
                          label='Repair Type'
                          variant='outlined'
                        />
                      )}
                      value={form.repairType}
                      loading={repairTypesLoading}
                      onInputChange={(_, e) => {
                        setForm(f => ({ ...f, repairType: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
                        setRepairTypeIsValid(validateStrict(e, repairTypes, 'repairTypeName'))
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      disableClearable={true}
                    />
                    <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                      <DatePicker
                        id='originalShipDatePicker'
                        label='Original Ship Date'
                        inputFormat='MM/DD/YYYY'
                        value={form.originalShipDate}
                        onChange={date => setForm(f => ({ ...f, originalShipDate: date }))}
                        renderInput={params => <TextField size='small' {...params} />}
                      />
                    </div>
                  </>
                }
              />

              <CustomAccordion
                defaultExpanded
                title={<h3>Repair info</h3>}
                titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                detailStyle={{ marginTop: '10px' }}
                children={
                  <>
                    <Stack direction='row'>
                      <div style={{ width: '225px' }}>
                        <p style={{ ...classes.accordionForm, marginBottom: '-10px', fontWeight: 'bold' }}>
                          Boards
                          <Tooltip title='Add another board'>
                            <IconButton size='small' onClick={() => addBoard()} style={{ marginLeft: '109px' }}>
                              <AddCircleOutline />
                            </IconButton>
                          </Tooltip>
                        </p>
                        <Divider style={{ ...classes.formEntry }} />
                        {form.boardType.map((board, index) => {
                          return (
                            <CustomAccordion
                              expanded={expanded === `${board.boardTypeName}`}
                              onChange={openAccordion(`${board.boardTypeName}`)}
                              title={board.boardTypeName !== '' ? board.boardTypeName : 'None Selected'}
                              key={index}
                              style={{ ...classes.accordionForm }}
                              titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                              children={
                                <Stack sx={{ marginTop: '15px', padding: '10px' }}>
                                  <Autocomplete
                                    id={`boardTypeName${index}Autocomplete`}
                                    size='small'
                                    sx={{
                                      width: '100%'
                                    }}
                                    options={
                                      boardTypes
                                        ? boardTypes
                                            .filter(option => !option.isArchived)
                                            .map(boardType => boardType.boardTypeName)
                                            .sort()
                                        : []
                                    }
                                    renderInput={params => (
                                      <TextField {...params} size='small' label='Board Type' variant='outlined' />
                                    )}
                                    value={board.boardTypeName}
                                    loading={boardTypesLoading}
                                    onInputChange={(_, e) => {
                                      setBoardType(e.replace(',', ' -'), index)
                                      setExpanded(e)
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    disableClearable={true}
                                    freeSolo={true}
                                  />
                                  <div style={{ display: 'flex', width: '100%' }}>
                                    <Autocomplete
                                      id={`designators${index}Autocomplete`}
                                      size='small'
                                      sx={{
                                        width: '100%',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                      }}
                                      key={index}
                                      options={
                                        boardTypes
                                          ? boardTypes
                                              .filter(boardOption => boardOption.boardTypeName === board.boardTypeName)
                                              .map(currentBoard => currentBoard.designators)
                                              .reduce((first, second) => {
                                                return first.concat(second)
                                              }, [])
                                              .sort()
                                          : []
                                      }
                                      renderInput={params => (
                                        <TextField size='small' {...params} label='Designator' variant='outlined' />
                                      )}
                                      value={newDesignator}
                                      loading={boardTypesLoading}
                                      onKeyDown={e => {
                                        if (e.key === 'Enter') setDesignator(newDesignator, index)
                                      }}
                                      onInputChange={(_, e) => setNewDesignator(e.replace(',', ' -'), index)}
                                      onBlur={e => setDesignator(e.target.value, index)}
                                      isOptionEqualToValue={(option, value) => option.value === value.value}
                                      disableClearable={true}
                                      freeSolo={true}
                                    />
                                    <IconButton
                                      id={`board${index}AddDesignatorButton`}
                                      style={{
                                        marginTop: '10px'
                                      }}
                                      onClick={() => setDesignator(newDesignator, index)}
                                      title='Add Designator'
                                    >
                                      <AddCircleOutline />
                                    </IconButton>
                                  </div>
                                  <div>
                                    {board.designators.map((designator, desIndex) => {
                                      return (
                                        <Chip
                                          id={`board${index}designator${desIndex}DeleteDesignatorButton`}
                                          key={desIndex}
                                          label={designator}
                                          variant='outlined'
                                          onDelete={() => {
                                            deleteDesignator(index, desIndex)
                                          }}
                                        />
                                      )
                                    })}
                                  </div>
                                  <CenteredDiv>
                                    <RegularButton
                                      id={`board${index}RemoveBoardButton`}
                                      onClick={() => removeBoard(index)}
                                      size='small'
                                      style={{ marginTop: '10px', border: '2px solid red', color: 'red', display: 'block' }}
                                    >
                                      Remove Board
                                    </RegularButton>
                                  </CenteredDiv>
                                </Stack>
                              }
                            />
                          )
                        })}
                        <FormControlLabel
                          style={{ margin: '10px' }}
                          control={
                            <Switch
                              id='isRapidRepairSwitch'
                              checked={form.isRapidRepair ? true : false}
                              onChange={e =>
                                setForm(f => ({
                                  ...f,
                                  isRapidRepair: form.isRapidRepair ? false : true
                                }))
                              }
                            />
                          }
                          label='Rapid Repair'
                        />
                        <FormControlLabel
                          style={{ margin: '10px' }}
                          control={
                            <Switch
                              id='outOfBoxFailureSwitch'
                              checked={form.outOfBoxFailure === 'Yes' || form.outOfBoxFailure === 'Y' ? true : false}
                              onChange={e =>
                                setForm(f => ({
                                  ...f,
                                  outOfBoxFailure: form.outOfBoxFailure === 'Yes' || form.outOfBoxFailure === 'Y' ? 'No' : 'Yes'
                                }))
                              }
                            />
                          }
                          label='Out Of Box Failure'
                        />
                      </div>
                      <div style={{ width: '225px' }}>
                        <TextField
                          id='boardRevTextField'
                          size='small'
                          onChange={e => setForm(f => ({ ...f, boardRev: e.target.value.replace(',', ' -') }))}
                          value={form.boardRev}
                          variant='outlined'
                          label='Board Rev'
                          sx={classes.formEntry}
                        />
                        <Tooltip title={'This is the repair fee amount.'}>
                          <TextField
                            id='repairFeeTextField'
                            size='small'
                            label='Repair Fee'
                            variant='outlined'
                            value={form.amountSpent}
                            onChange={e => setForm(f => ({ ...f, amountSpent: e.target.value.replace(',', ' -') }))}
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>$</InputAdornment>
                            }}
                            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                          />
                        </Tooltip>
                        <Tooltip title={'This is the cause/reason why the failure occurred.'}>
                          <Autocomplete
                            id='problemsFoundAutocomplete'
                            size='small'
                            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                            options={
                              problemsFound
                                ? problemsFound
                                    .filter(option => !option.isArchived)
                                    .map(problemFound => problemFound.problemsFoundName)
                                    .sort()
                                : []
                            }
                            renderInput={params => (
                              <TextField size='small' {...params} label='Cause of Problem Found' variant='outlined' />
                            )}
                            value={form.problemFound}
                            loading={problemsFoundLoading}
                            onInputChange={(_, e) => setForm(f => ({ ...f, problemFound: e.replace(',', ' -') }))}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            disableClearable={true}
                          />
                        </Tooltip>
                        <Tooltip title={'These are notes our technicians make about the repair.'}>
                          <TextField
                            id='repairCommentTextField'
                            size='small'
                            multiline
                            label='Repair Comment'
                            variant='outlined'
                            value={form.repairComments}
                            onChange={e => setForm(f => ({ ...f, repairComments: e.target.value.replace(',', ' -') }))}
                            sx={classes.formEntry}
                          />
                        </Tooltip>
                      </div>
                      <div style={{ width: '225px' }}>
                        <Tooltip title={"This is what the repair fee would have been if it hadn't been waived."}>
                          <TextField
                            id='amountWaivedTextField'
                            size='small'
                            onChange={e => setForm(f => ({ ...f, amountWaived: e.target.value.replace(',', ' -') }))}
                            value={form.amountWaived ? form.amountWaived : ''}
                            variant='outlined'
                            label='Amount Waived'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>$</InputAdornment>
                            }}
                            sx={classes.formEntry}
                          />
                        </Tooltip>
                        <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                          <DatePicker
                            id='completedDatePicker'
                            label='Completed Date'
                            inputFormat='MM/DD/YYYY'
                            value={form.completedDate}
                            onChange={date => setForm(f => ({ ...f, completedDate: date }))}
                            renderInput={params => <TextField size='small' {...params} />}
                          />
                        </div>
                        <Autocomplete
                          id='initialNameAutocomplete'
                          size='small'
                          style={{ ...classes.formEntry, display: 'inline-block' }}
                          options={
                            initials
                              ? initials
                                  .filter(option => !option.isArchived)
                                  .map(initial => initial.initialName)
                                  .sort()
                              : []
                          }
                          renderInput={params => <TextField size='small' {...params} label='Repair Tech.' variant='outlined' />}
                          value={form.initials}
                          loading={initialsLoading}
                          onInputChange={(_, e) => setForm(f => ({ ...f, initials: e.toUpperCase().replace(',', ' -') }))}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          disableClearable={true}
                          freeSolo={true}
                        />
                        <Tooltip title={'This is what the customer stated the issue they saw was.'}>
                          <Autocomplete
                            id='customerComplaintNameAutocomplete'
                            size='small'
                            style={{ ...classes.formEntry, display: 'inline-block' }}
                            options={
                              customerComplaints
                                ? customerComplaints
                                    .filter(option => !option.isArchived)
                                    .map(customerComplaint => customerComplaint.customerComplaintName)
                                    .sort()
                                : []
                            }
                            renderInput={params => (
                              <TextField size='small' {...params} label='Customer Complaint' variant='outlined' />
                            )}
                            value={form.customerComplaint}
                            loading={customerComplaintsLoading}
                            onInputChange={(_, e) =>
                              setForm(f => ({
                                ...f,
                                customerComplaint: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                              }))
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            disableClearable={true}
                            freeSolo={true}
                          />
                        </Tooltip>

                        <Tooltip title={'This is what our technicians found to be the issue.'}>
                          <Autocomplete
                            id='finalDeterminationAutocomplete'
                            size='small'
                            style={{ ...classes.formEntry, display: 'inline-block' }}
                            options={
                              customerComplaints
                                ? customerComplaints
                                    .filter(option => !option.isArchived)
                                    .map(customerComplaint => customerComplaint.customerComplaintName)
                                    .sort()
                                : []
                            }
                            renderInput={params => (
                              <TextField size='small' {...params} label='Final Determination' variant='outlined' />
                            )}
                            value={form.finalDetermination}
                            loading={customerComplaintsLoading}
                            onInputChange={(_, e) =>
                              setForm(f => ({
                                ...f,
                                finalDetermination: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                              }))
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            disableClearable={true}
                            freeSolo={true}
                          />
                        </Tooltip>

                        <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                          <DatePicker
                            id='buildDatePicker'
                            label='Build Date'
                            inputFormat='MM/DD/YYYY'
                            value={form.buildDate}
                            onChange={date => setForm(f => ({ ...f, buildDate: date }))}
                            renderInput={params => <TextField size='small' {...params} />}
                          />
                        </div>
                      </div>
                    </Stack>
                  </>
                }
              />
            </Grid>
            <Grid item md={12} lg={3}>
              <CustomAccordion
                hidden={submittedProducts.length < 1}
                defaultExpanded
                title={<h3>Submitted Products</h3>}
                titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                detailStyle={{ marginTop: '10px' }}
                children={
                  <>
                    {submittedProducts.map((item, index) => {
                      return <h5 key={index}>• {item}</h5>
                    })}
                  </>
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
