import { acquireAccessToken } from '@wavetronix/common-components'
import axios from 'axios'
import { env } from '../index.js'

let instance = null

class RmaApi {
  getRmas = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.rmaURL}/api/rma`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
  submitRma = async (msalInstance, accounts, rma) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.rmaURL}/api/rma`, rma, { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 })
      .then(res => res)
  }

  updateRma = async (msalInstance, accounts, rma) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .put(`${env.urls.rmaURL}/api/rma`, rma, { headers: { Authorization: `Bearer ${token}` }, timeout: 30000 })
      .then(res => res)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new RmaApi()
  }
  return instance
}
export default getInstance()
