import { acquireAccessToken } from '@wavetronix/common-components'
import axios from 'axios'
import { env } from '../index.js'

let instance = null

class StatsApi {
  getDataGraphInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/general`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetBoardTypeInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/boardType`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetDesignatorInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/designator`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetDaysOpenInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/daysOpen`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetMonthlyInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/monthlyInfo`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }

  GetMonthlyBoardInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/monthlyBoardInfo`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetMonthlyTotalInfo = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/monthlyTotalInfo`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
  GetStateData = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/stateData`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result.map(s => ({
      ...s,
      hexCount:
        s.count < 10 ? ('0' + s.count).slice(-2) : s.count < 16 && s.count > 9 ? '0' + s.count.toString(16) : s.count.toString(16)
    }))
  }

  GetTrailing12Months = async (msalInstance, accounts, filter) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    let result = []
    await axios
      .post(`${env.urls.rmaURL}/api/stats/trailing12month`, filter, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => (result = res.data))
    return result
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new StatsApi()
  }
  return instance
}
export default getInstance()
