import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export default function StatsSelect({ style, required, onChange, value, label, options, disabled, height }) {
  const useStyles = makeStyles()({
    menuPaper: {
      maxHeight: height
    }
  })
  const { classes } = useStyles()
  return (
    <FormControl style={style} variant='outlined' size='small' required={required}>
      <InputLabel id='selectLabel'>{label}</InputLabel>
      <Select
        id='statsSelect'
        variant='outlined'
        labelId='selectLabel'
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
        MenuProps={{ disableScrollLock: true, classes: { paper: classes.menuPaper } }}
      >
        {options.map(x => (
          <MenuItem id={x} value={x} key={x}>
            {x}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
