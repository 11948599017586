import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { CustomAccordion, ListSelector, inDateRange } from '@wavetronix/common-components'
import dayjs from 'dayjs'
import React from 'react'

export const DEFAULT_RMASTATS_FILTER = {
  dealer: [],
  customer: [],
  product: [],
  productType: [],
  designator: '',
  openDateStart: dayjs().add(-1, 'year'),
  openDateEnd: dayjs()
}

export const filterStatsRmas = (filter, rmas) =>
  rmas
    ? rmas.filter(r => {
        return (
          (filter.dealer.map(fp => fp.value).includes(r.dealer) || filter.dealer.length === 0) &&
          (filter.customer.map(fp => fp.value).includes(r.customer) || filter.customer.length === 0) &&
          (filter.product.map(fp => fp.value).includes(r.product) || filter.product.length === 0) &&
          r.boardType
            .map(board => board.designators.map(des => des))
            .toString()
            .includes(filter.designator.toString(fp => fp.value)) &&
          (filter.productType.map(fp => fp.value).includes(r.productType) || filter.productType.length === 0) &&
          inDateRange(r.openDate, filter.openDateStart, filter.openDateEnd) &&
          inDateRange(r.completedDate, filter.completedDateStart, filter.completedDateEnd)
        )
      })
    : []

const classes = {
  paper: {
    width: 300
  },
  accordianList: {
    borderRadius: '5px',
    marginTop: '15px',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  dateRoot: {
    width: '48%',
    '.MuiInputBase-root': { fontSize: '15px' },
    '.MuiButtonBase-root': {
      padding: '5px 5px 5px 0px'
    }
  },
  accordianSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px'
  },
  accordianDetails: { padding: '0px', margin: '0px', marginTop: '0px' }
}

export default function RmaStatsFilterDrawer({ filter, setFilter, dealers, productTypes, products, customers }) {
  return (
    <>
      {/* <div style={{ width: '100%' }}></div> */}
      <CustomAccordion
        title='Dealers'
        style={{ ...classes.accordianList, marginTop: 0 }}
        titleStyle={classes.accordianSummary}
        detailStyle={classes.accordianDetails}
        children={
          <ListSelector
            selectedOptions={filter.dealer}
            options={[
              ...(dealers
                ? dealers
                    .filter(dealer => !dealer.isArchived)
                    .map(dealer => ({ id: dealer.id, value: dealer.dealerName }))
                    .sort((a, b) => {
                      let fa = a.value,
                        fb = b.value

                      if (fa < fb) {
                        return -1
                      }
                      if (fa > fb) {
                        return 1
                      }
                      return 0
                    })
                : [])
            ]}
            onChange={e => {
              setFilter(f => ({ ...f, dealer: [...e.map(v => ({ id: v.id, dealerName: v.value, value: v.value }))] }))
            }}
          />
        }
      />
      <CustomAccordion
        title='Customers'
        style={classes.accordianList}
        titleStyle={classes.accordianSummary}
        detailStyle={classes.accordianDetails}
        children={
          <ListSelector
            selectedOptions={filter.customer}
            options={[
              ...(customers
                ? customers
                    .filter(customer => !customer.isArchived)
                    .map(customer => ({ id: customer.id, value: customer.customerName }))
                    .sort((a, b) => {
                      let fa = a.value,
                        fb = b.value

                      if (fa < fb) {
                        return -1
                      }
                      if (fa > fb) {
                        return 1
                      }
                      return 0
                    })
                : [])
            ]}
            onChange={e =>
              setFilter(f => ({ ...f, customer: [...e.map(v => ({ id: v.id, customerName: v.value, value: v.value }))] }))
            }
          />
        }
      />

      <CustomAccordion
        title='Product Types'
        style={classes.accordianList}
        titleStyle={classes.accordianSummary}
        detailStyle={classes.accordianDetails}
        children={
          <ListSelector
            selectedOptions={filter.productType}
            options={[
              ...(productTypes
                ? productTypes
                    .filter(type => !type.isArchived)
                    .map(type => ({ id: type.id, value: type.productTypeName }))
                    .sort((a, b) => {
                      let fa = a.value,
                        fb = b.value

                      if (fa < fb) {
                        return -1
                      }
                      if (fa > fb) {
                        return 1
                      }
                      return 0
                    })
                : [])
            ]}
            onChange={e =>
              setFilter(f => ({ ...f, productType: [...e.map(v => ({ id: v.id, productTypeName: v.value, value: v.value }))] }))
            }
          />
        }
      />

      <CustomAccordion
        title='Products'
        style={classes.accordianList}
        titleStyle={classes.accordianSummary}
        detailStyle={classes.accordianDetails}
        children={
          <ListSelector
            selectedOptions={filter.product}
            options={[
              ...(products
                ? products
                    .filter(option => !option.isArchived)
                    .map(product => ({ id: product.id, value: product.productName }))
                    .sort((a, b) => {
                      let fa = a.value,
                        fb = b.value

                      if (fa < fb) {
                        return -1
                      }
                      if (fa > fb) {
                        return 1
                      }
                      return 0
                    })
                : [])
            ]}
            onChange={e =>
              setFilter(f => ({ ...f, product: [...e.map(v => ({ id: v.id, productName: v.value, value: v.value }))] }))
            }
          />
        }
      />

      <TextField
        id='filterDesignatorTextField'
        style={{ marginTop: 15 }}
        label={'Designator'}
        size='small'
        value={filter.designator}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, designator: e.target.value }))}
      />
      <h5 style={{ margin: '15px 15px 0px 5px' }}>Open Date</h5>
      <div style={{ marginTop: 10 }}>
        <DatePicker
          id='filterOpenDateStartDatePicker'
          label='Start'
          inputFormat='MM/DD/YYYY'
          value={filter.openDateStart}
          onChange={date => setFilter(f => ({ ...f, openDateStart: date }))}
          renderInput={params => <TextField size='small' sx={classes.dateRoot} {...params} />}
        />
        <DatePicker
          id='filterOpenDateEndDatePicker'
          label='End'
          inputFormat='MM/DD/YYYY'
          value={filter.openDateEnd}
          onChange={date => setFilter(f => ({ ...f, openDateEnd: date }))}
          renderInput={params => <TextField size='small' sx={{ ...classes.dateRoot, marginLeft: '5px' }} {...params} />}
        />
      </div>
    </>
  )
}
