import { useMsal } from '@azure/msal-react'
import { Archive, ArrowBack, UnarchiveOutlined } from '@mui/icons-material'
import {
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Tooltip
} from '@mui/material'
import { Stack } from '@mui/system'
import { CenteredDiv, HasAccess, RegularButton, SnackbarVariants } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ListOptionsApi from '../api/ListOptionsApi'
import { useEffect } from 'react'
import { env } from '../index.js'

const classes = {
  archiveButton: { float: 'right', display: 'inline', padding: '0px', margin: '0px' },
  listItem: { margin: '10px', flex: 'right' },
  listCard: { width: '50%', border: 'none', boxShadow: 'none' }
}

const uppercase = ['States', 'Countries', 'Products', 'Repair Tech.']

export default function ListManager({
  repairTypes,
  repairTypesLoading,
  repairTypesRefetch,
  dealers,
  dealerLoading,
  dealerRefetch,
  productTypes,
  productTypesLoading,
  productTypesRefetch,
  products,
  productsLoading,
  productsRefetch,
  boardTypes,
  boardTypesLoading,
  boardTypesRefetch,
  problemsFound,
  problemsFoundLoading,
  problemsFoundRefetch,
  issues,
  issuesLoading,
  issuesRefetch,
  initials,
  initialsLoading,
  initialsRefetch,
  customerComplaints,
  customerComplaintsLoading,
  customerComplaintsRefetch,
  repairComments,
  repairCommentsLoading,
  repairCommentsRefetch,
  customers,
  customersLoading,
  customersRefetch,
  states,
  statesLoading,
  statesRefetch,
  countries,
  countriesLoading,
  countriesRefetch,
  cities,
  citiesLoading,
  citiesRefetch
}) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()
  const [selectedOptions, setSelectedOptions] = useState({
    index: 0,
    list: [],
    title: '',
    option: '',
    name: '',
    refetch: ''
  })
  const [entry, setEntry] = useState('')

  const handleListItemClick = (event, currentList) => {
    setSelectedOptions(currentList)
  }

  async function archive(item, optionList, refetch) {
    item.isArchived = !item.isArchived
    let key = enqueueSnackbar(`Changing Archiving Status`, SnackbarVariants.LOADING)
    await ListOptionsApi.archiveListOption(instance, accounts, item, optionList)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Archive Status Changed`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Change Archive Status`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  useEffect(() => {
    setEntry('')
  }, [selectedOptions])

  async function createNewDropOption(item, optionList, refetch) {
    let key = enqueueSnackbar(`Saving ${optionList}...`, SnackbarVariants.LOADING)
    let tempOptions = selectedOptions
    await ListOptionsApi.addNewDropOption(instance, accounts, { [`${optionList}Name`]: item }, optionList)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${optionList} was Added`, SnackbarVariants.SUCCESS)
        tempOptions.list.push({ [`${optionList}Name`]: item, isArchived: false })
        setSelectedOptions(tempOptions)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${optionList}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  function returnHome() {
    history.goBack()
  }

  const customListButton = (index, list, title, option, name, refetch) => {
    return (
      <>
        <ListItemButton
          id={`${title}${option}ListItemButton`}
          selected={selectedOptions.index === index}
          onClick={event => {
            handleListItemClick(event, {
              index: index,
              list: list.sort((a, b) => {
                if (a[name] < b[name]) return -1
                else return 1
              }),
              title: title,
              option: option,
              name: name,
              refetch: refetch
            })
          }}
        >
          <ListItemText primary={title} />
        </ListItemButton>
      </>
    )
  }

  const listCard = activeList => {
    return (
      <>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={6}>
            <CenteredDiv>
              <h2 style={{ marginTop: '24px' }}>{activeList.title === '' ? 'Select a list' : activeList.title}</h2>
            </CenteredDiv>
          </Grid>
          <Grid item xs={6}>
            <CenteredDiv>
              <TextField
                id={`newEntryOptionTextField`}
                style={{ margin: '18px' }}
                size='small'
                disabled={selectedOptions.title === ''}
                value={entry}
                onChange={e => {
                  setEntry(
                    uppercase.includes(selectedOptions.title)
                      ? e.target.value.toUpperCase().replace(',', ' -')
                      : e.target.value.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                  )
                }}
              ></TextField>
              <RegularButton
                id={`entryAddButton`}
                style={{ marginRight: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                disabled={selectedOptions.title === '' || entry === ''}
                onClick={() => {
                  createNewDropOption(entry, activeList.option, activeList.refetch)
                  setEntry('')
                }}
              >
                Add Entry
              </RegularButton>
            </CenteredDiv>
          </Grid>
        </Grid>
        <Divider style={{ margin: '0px 10px' }} />
        <Stack direction='row' style={{ justifyContent: 'space-between' }}>
          <Card style={{ ...classes.listCard }}>
            <CenteredDiv>
              <h5 style={{ margin: '5px' }}>Active</h5>
            </CenteredDiv>
            {/* <Divider style={{ margin: '0px 100px' }} /> */}
            {activeList.list
              ? activeList.list
                  .filter(option => !option.isArchived)
                  .map((item, index) => {
                    return (
                      <div key={index} style={{ ...classes.listItem }}>
                        <div style={{ display: 'inline' }}>{item[activeList.name]}</div>
                        <div style={{ display: 'inline' }}>
                          {item.isArchived ? (
                            <Tooltip title='Unarchive' placement='right'>
                              <IconButton
                                id={`${activeList.name}UnarchiveButton`}
                                onClick={() => archive(item, activeList.option, activeList.refetch)}
                                tooltip='archive'
                                style={{ ...classes.archiveButton }}
                              >
                                <UnarchiveOutlined />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Archive' placement='right'>
                              <IconButton
                                id={`${activeList.name}ArchiveButton`}
                                onClick={() => archive(item, activeList.option, activeList.refetch)}
                                tooltip='archive'
                                style={{ ...classes.archiveButton }}
                              >
                                <Archive />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )
                  })
              : []}
          </Card>
          <div>
            <Divider orientation='vertical' style={{ minHeight: '625px' }} />
          </div>
          <Card style={{ ...classes.listCard }}>
            <CenteredDiv>
              <h5 style={{ margin: '5px' }}>Archived</h5>
            </CenteredDiv>
            {/* <Divider style={{ margin: '0px 100px' }} /> */}
            {activeList.list
              ? activeList.list
                  .filter(option => option.isArchived)
                  .map((item, index) => {
                    return (
                      <div key={index} style={{ ...classes.listItem }}>
                        <div style={{ display: 'inline' }}>{item[activeList.name]}</div>
                        <div style={{ display: 'inline' }}>
                          {item.isArchived ? (
                            <Tooltip title='Unarchive' placement='right'>
                              <IconButton
                                id={`${activeList.name}UnarchiveButton`}
                                onClick={() => {
                                  archive(item, activeList.option, activeList.refetch)
                                }}
                                tooltip='archive'
                                style={{ ...classes.archiveButton }}
                              >
                                <UnarchiveOutlined />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title='Archive' placement='right'>
                              <IconButton
                                id={`${activeList.name}ArchiveButton`}
                                onClick={() => {
                                  archive(item, activeList.option, activeList.refetch)
                                }}
                                tooltip='archive'
                                style={{ ...classes.archiveButton }}
                              >
                                <Archive />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    )
                  })
              : []}
          </Card>
        </Stack>
      </>
    )
  }

  return (
    <>
      <HasAccess
        allowedRoles={['RMA User']}
        env={env}
        unauthorizedControl={
          <CenteredDiv>
            <h1>403 Unauthorized</h1>
          </CenteredDiv>
        }
      >
        <RegularButton id='backButton' onClick={returnHome} style={{ margin: '24px 0px -20px 24px' }}>
          <ArrowBack />
          Return
        </RegularButton>
        <Stack direction='row' style={{ width: '100%', margin: '48px 24px' }}>
          <Card style={{ width: '30%', minWidth: '200px' }}>
            <List
              subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                  Manage Lists
                </ListSubheader>
              }
              style={{ margin: '10px 24px' }}
            >
              <Divider />
              {customListButton(11, boardTypes, 'Board Types', 'boardType', 'boardTypeName', boardTypesRefetch)}
              {customListButton(6, cities, 'Cities', 'city', 'cityName', citiesRefetch)}
              {customListButton(14, countries, 'Countries', 'country', 'countryName', countriesRefetch)}
              {customListButton(
                8,
                customerComplaints,
                'Customer Complaints',
                'customerComplaint',
                'customerComplaintName',
                customerComplaintsRefetch
              )}
              {customListButton(7, customers, 'Customers', 'customer', 'customerName', customersRefetch)}
              {customListButton(13, dealers, 'Dealers', 'dealer', 'dealerName', dealerRefetch)}
              {customListButton(2, issues, 'Issues', 'issue', 'issueName', issuesRefetch)}
              {customListButton(1, problemsFound, 'Problems Found', 'problemsFound', 'problemsFoundName', problemsFoundRefetch)}
              {customListButton(12, products, 'Products', 'product', 'productName', productsRefetch)}
              {customListButton(4, productTypes, 'Product Types', 'productType', 'productTypeName', productTypesRefetch)}
              {customListButton(
                9,
                repairComments,
                'Repair Comments',
                'repairComment',
                'repairCommentName',
                repairCommentsRefetch
              )}
              {customListButton(3, initials, 'Repair Tech.', 'Initial', 'initialName', initialsRefetch)}
              {customListButton(10, repairTypes, 'Repair Types', 'repairType', 'repairTypeName', repairTypesRefetch)}
              {customListButton(5, states, 'States', 'state', 'stateName', statesRefetch)}
            </List>
          </Card>
          <Card style={{ width: '65%', minWidth: '300px', marginRight: '24px' }}>{listCard(selectedOptions)}</Card>
        </Stack>
      </HasAccess>
    </>
  )
}
