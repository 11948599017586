import { useMsal } from '@azure/msal-react'
import { AddCircleOutline, ArrowLeftOutlined, Business, Person, QrCode, QrCode2 } from '@mui/icons-material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import EditIcon from '@mui/icons-material/Edit'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MicrowaveIcon from '@mui/icons-material/Microwave'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import SensorsIcon from '@mui/icons-material/Sensors'
import {
  Autocomplete,
  Stack,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import {
  CenteredDiv,
  CustomAccordion,
  HasAccess,
  PrimaryButton,
  RegularButton,
  SnackbarVariants,
  WtxColors,
  acquireAccessToken,
  getConfiguration
} from '@wavetronix/common-components'
import axios from 'axios'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { DEFAULT_FORM } from '../ListOptions'
import ListOptionsApi from '../api/ListOptionsApi'
import RmaApi from '../api/RmaApi'
import { env } from '../index.js'
import EditRmaModal from './EditRmaModal'
import ReadOnlyDetailView from './ReadOnlyDetailView'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const classes = {
  formEntry: {
    margin: '10px 10px 10px 0px',
    width: '205px'
  },
  AccordionEntry: {
    margin: '10px 0px 10px 0px'
  },
  card: {
    width: '85%',
    marginTop: '15px',
    border: 'none',
    boxShadow: 'none'
  },
  innerCard: {
    width: '100%',
    height: '95%',
    margin: '15px 0px 15px 0px'
  },
  detailInfo: { fontSize: '18px', marginTop: 'auto', marginLeft: '10px' },
  detailInfoDiv: { display: 'flex', marginTop: '20px' }
}

const msPerDay = 1000 * 60 * 60 * 24

const ProductHash = product => {
  if (product === 'Click') {
    return <MicrowaveIcon fontSize='large' />
  } else if (product === 'Cable') {
    return <ElectricalServicesIcon fontSize='large' />
  } else if (product === 'Expanse') {
    return <RocketLaunchIcon fontSize='large' />
  } else {
    return <SensorsIcon fontSize='large' />
  }
}

export default function RmaDetailView({
  boardTypes,
  boardTypesLoading,
  boardTypesRefetch,
  problemsFound,
  problemsFoundLoading,
  initials,
  initialsLoading,
  initialsRefetch,
  customerComplaints,
  customerComplaintsLoading,
  customerComplaintsRefetch,
  repairCommentsRefetch,
  repairTypes,
  repairTypesLoading,
  repairTypesRefetch,
  dealers,
  dealerLoading,
  dealerRefetch,
  productTypes,
  productTypesLoading,
  productTypesRefetch,
  products,
  productsLoading,
  productsRefetch,
  customers,
  customersLoading,
  customersRefetch,
  states,
  statesLoading,
  statesRefetch,
  countries,
  countriesLoading,
  countriesRefetch,
  cities,
  citiesLoading,
  citiesRefetch
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { accounts, instance } = useMsal()
  const [rma, setRma] = useState(DEFAULT_FORM)
  const location = useLocation()
  const history = useHistory()
  const [editRmaModal, setEditRmaModal] = useState(false)
  const [newDesignator, setNewDesignator] = useState('')
  const [expanded, setExpanded] = useState(false)

  let querystring = ''
  if (location.search.length > 0 && location.search[0] === '?') {
    querystring = location.search.substring(1)
  }
  let itemNumber = qs.parse(querystring).id

  async function getRmaInfo() {
    let token = await acquireAccessToken(instance, accounts, env)
    return axios.get(`${env.urls.rmaURL}/api/rma/${itemNumber}`, getConfiguration(token)).then(res => {
      return res.data
    })
  }

  async function updateDesignatorList(item, optionList, refetch) {
    let key = enqueueSnackbar(`Adding New Designator...`, SnackbarVariants.LOADING)
    await ListOptionsApi.archiveListOption(instance, accounts, item, optionList)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Added New Designator`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add New Designator`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  useEffect(() => {
    let close = dayjs().utc()
    let open = dayjs(rma.openDate).utc()
    if (rma.completedDate) {
      close = dayjs(rma.completedDate).utc()
    }
    let diffTime = Math.floor(Math.floor(close - open) / msPerDay)
    setRma(f => ({ ...f, daysOpen: diffTime.toString() }))
  }, [rma.daysOpen, rma.openDate, rma.completedDate])

  async function saveChanges() {
    let key = enqueueSnackbar('Saving RMA...', SnackbarVariants.LOADING)
    await RmaApi.updateRma(instance, accounts, rma)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar('RMA was updated', SnackbarVariants.SUCCESS)
        returnHome()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to update RMA', SnackbarVariants.ERROR)
      })
  }

  async function createNewDropOption(OptionEntry, ListType, refetch) {
    let key = enqueueSnackbar(`Saving ${ListType}...`, SnackbarVariants.LOADING)
    await ListOptionsApi.addNewDropOption(instance, accounts, { [`${ListType}Name`]: OptionEntry }, ListType)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${ListType} was Added`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${ListType}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  async function createNewBoardDropOption(OptionEntry, ListType, refetch) {
    let key = enqueueSnackbar(`Saving ${ListType}...`, SnackbarVariants.LOADING)
    await ListOptionsApi.addNewDropOption(instance, accounts, OptionEntry, ListType)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${ListType} was Added`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${ListType}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  const handleSave = event => {
    event.preventDefault()
    let existingBoardType = false
    let existingInitials = false
    let existingCustomerComplaint = false
    let existingRepairType = false
    let existingDesignator = false

    for (let board of rma.boardType) {
      for (let existingBoard of boardTypes) {
        if (board.boardTypeName === existingBoard.boardTypeName) {
          existingBoardType = true
          for (let designator of board.designators) {
            existingDesignator = false
            for (let existingDes of existingBoard.designators) {
              if (designator === existingDes) {
                existingDesignator = true
              }
            }
            if (!existingDesignator && designator !== '' && board) {
              existingBoard.designators.push(designator)
              updateDesignatorList(existingBoard, 'boardType', boardTypesRefetch)
            }
          }
        }
      }

      if (!existingBoardType && board.boardTypeName !== '' && board) {
        createNewBoardDropOption(board, 'BoardType', boardTypesRefetch)
      }
    }
    for (let j = 0; j < rma.boardType.length; j++) {
      if (rma.boardType[j].boardTypeName === '') removeBoard(j)
    }
    for (let i = 0; i < initials.length; i++) {
      if (initials[i].initialName === rma.initials) existingInitials = true
    }
    for (let i = 0; i < customerComplaints.length; i++) {
      if (customerComplaints[i].customerComplaintName === rma.customerComplaint) existingCustomerComplaint = true
    }
    for (let i = 0; i < repairTypes.length; i++) {
      if (repairTypes[i].repairTypeName === rma.repairType) existingRepairType = true
    }
    if (!existingInitials && rma.initials !== '' && rma.initials) createNewDropOption(rma.initials, 'Initial', initialsRefetch)
    if (!existingCustomerComplaint && rma.customerComplaint !== '' && rma.customerComplaint)
      createNewDropOption(rma.customerComplaint, 'CustomerComplaint', customerComplaintsRefetch)
    if (!existingRepairType && rma.repairType !== '' && rma.repairType)
      createNewDropOption(rma.repairType, 'RepairType', repairTypesRefetch)
    saveChanges()
  }
  const { isLoading, data, refetch } = useQuery({ queryKey: ['rmaInfo'], queryFn: getRmaInfo })

  function returnHome() {
    refetch()
    history.goBack()
  }

  useEffect(() => {
    if (
      data &&
      (!rma ||
        data.id !== rma.id ||
        data.customer !== rma.customer ||
        data.dealer !== rma.dealer ||
        data.city !== rma.city ||
        data.state !== rma.state ||
        data.country !== rma.country ||
        data.product !== rma.product ||
        data.productType !== rma.productType ||
        data.serialNumber !== rma.serialNumber ||
        data.openDate !== rma.openDate ||
        data.rmaNumber !== rma.rmaNumber)
    ) {
      setRma(data)
    }
  }, [data, rma])

  function addBoard() {
    let previousBoardList = rma.boardType
    previousBoardList.push({ boardTypeName: '', designators: [] })
    setRma(f => ({ ...f, boardType: previousBoardList }))
  }
  function removeBoard(board) {
    let previousBoardList = rma.boardType
    previousBoardList.splice(board, 1)
    setRma(f => ({ ...f, boardType: previousBoardList }))
  }
  function setBoardType(e, board) {
    let previousBoardList = rma.boardType
    previousBoardList[board].boardTypeName = e.replace(/\b\w/g, m => m.toUpperCase())
    setRma(f => ({ ...f, boardType: previousBoardList }))
  }
  function setDesignator(e, board) {
    let previousBoardList = rma.boardType
    if (e !== '') {
      previousBoardList[board].designators.push(e.toUpperCase())
    }
    setRma(f => ({ ...f, boardType: previousBoardList }))
    setNewDesignator('')
  }
  function deleteDesignator(board, designator) {
    let previousBoardList = rma.boardType
    previousBoardList[board].designators.splice(designator, 1)
    setRma(f => ({ ...f, boardType: previousBoardList }))
  }
  const openAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return isLoading || !data ? (
    <CenteredDiv>
      <CircularProgress />
    </CenteredDiv>
  ) : (
    <>
      <EditRmaModal
        open={editRmaModal}
        onClose={() => setEditRmaModal(false)}
        repairTypes={repairTypes}
        repairTypesLoading={repairTypesLoading}
        repairTypesRefetch={repairCommentsRefetch}
        dealers={dealers}
        dealerLoading={dealerLoading}
        dealerRefetch={dealerRefetch}
        productTypes={productTypes}
        productTypesLoading={productTypesLoading}
        productTypesRefetch={productTypesRefetch}
        products={products}
        productsLoading={productsLoading}
        productsRefetch={productsRefetch}
        itemNumber={itemNumber}
        refetchRma={refetch}
        rma={rma}
        customers={customers}
        customersLoading={customersLoading}
        customersRefetch={customersRefetch}
        states={states}
        statesLoading={statesLoading}
        statesRefetch={statesRefetch}
        countries={countries}
        countriesLoading={countriesLoading}
        countriesRefetch={countriesRefetch}
        cities={cities}
        citiesLoading={citiesLoading}
        citiesRefetch={citiesRefetch}
      />
      <div>
        <RegularButton onClick={returnHome} style={{ margin: '24px 0px 0px 24px' }}>
          <ArrowLeftOutlined /> Return
        </RegularButton>
      </div>
      <CenteredDiv>
        <Card sx={classes.card}>
          <CardHeader
            title={<div style={{ fontSize: '30px' }}>RMA - #{rma.rmaNumber}</div>}
            sx={{ backgroundColor: 'black', color: 'white' }}
            subheader={
              <div style={{ color: 'white', display: 'flex' }}>
                <CalendarMonthIcon />
                <div>{`${dayjs(rma.openDate).format('MM-DD-YYYY')} - ${rma.daysOpen} day(s)`} </div>
              </div>
            }
          />
          <CardContent>
            <div>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Card sx={classes.innerCard}>
                    <CardContent>
                      <HasAccess allowedRoles={['RMA User']} env={env}>
                        <EditIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setEditRmaModal(true)} />
                      </HasAccess>
                      <div style={{ marginTop: '30px' }}>
                        {rma.dealer === '' ? null : (
                          <div style={classes.detailInfoDiv}>
                            <Business fontSize='large' />
                            <span style={classes.detailInfo}>{`${rma.dealer}`} </span>
                          </div>
                        )}
                        {rma.customer === '' ? null : (
                          <div style={classes.detailInfoDiv}>
                            <Person fontSize='large' />
                            <span style={classes.detailInfo}>{`${rma.customer}`} </span>
                          </div>
                        )}
                        <div style={classes.detailInfoDiv}>
                          <LocationOnIcon fontSize='large' />
                          <span style={classes.detailInfo}>
                            {`${rma.city}, ${rma.state}${rma.country ? `, ${rma.country}` : ''}`}{' '}
                          </span>
                        </div>
                        <div style={classes.detailInfoDiv}>
                          {ProductHash(rma.productType)}
                          <span style={classes.detailInfo}>{`${rma.productType}`} </span>
                        </div>
                        <div style={classes.detailInfoDiv}>
                          <QrCode fontSize='large' />
                          <span style={classes.detailInfo}>{`${rma.product}`} </span>
                        </div>

                        <div style={classes.detailInfoDiv}>
                          <QrCode2 fontSize='large' />
                          <span style={classes.detailInfo}>{`${rma.serialNumber.toUpperCase()}`} </span>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <HasAccess allowedRoles={['RMA User']} env={env}>
                  <Grid item md={12} lg={8}>
                    <Card sx={classes.innerCard}>
                      <CardContent>
                        <Grid container justifyContent='space-evenly'>
                          <Grid>
                            <Grid container justifyContent='space-evenly' direction='column'>
                              <p style={{ marginBottom: '-10px', fontWeight: 'bold' }}>
                                Boards
                                <Tooltip title='Add another board'>
                                  <IconButton
                                    id='boardAddButton'
                                    size='small'
                                    onClick={() => addBoard()}
                                    style={{ marginLeft: '109px' }}
                                  >
                                    <AddCircleOutline />
                                  </IconButton>
                                </Tooltip>
                              </p>{' '}
                              <Divider style={{ ...classes.formEntry }} />
                              {rma.boardType.map((board, index) => {
                                return (
                                  <CustomAccordion
                                    title={board.boardTypeName !== '' ? board.boardTypeName : 'None Selected'}
                                    key={index}
                                    style={{ width: '205px' }}
                                    titleStyle={{ backgroundColor: WtxColors.CONCRETE }}
                                    expanded={expanded === `${board.boardTypeName}`}
                                    onChange={openAccordion(`${board.boardTypeName}`)}
                                    children={
                                      <Stack sx={{ marginTop: '15px', padding: '10px' }}>
                                        <Autocomplete
                                          id='boardTypeNameAutocomplete'
                                          size='small'
                                          sx={{
                                            width: '100%'
                                          }}
                                          key={index}
                                          options={
                                            boardTypes
                                              ? boardTypes
                                                  .filter(option => !option.isArchived)
                                                  .map(boardType => boardType.boardTypeName)
                                                  .sort()
                                              : []
                                          }
                                          renderInput={params => (
                                            <TextField size='small' {...params} label='Board Type' variant='outlined' />
                                          )}
                                          value={board.boardTypeName}
                                          loading={boardTypesLoading}
                                          onInputChange={(_, e) => {
                                            setBoardType(e.replace(',', ' -'), index)
                                            setExpanded(e)
                                          }}
                                          isOptionEqualToValue={(option, value) => option.value === value.value}
                                          disableClearable={true}
                                          freeSolo={true}
                                        />
                                        <div style={{ display: 'flex', width: '100%' }}>
                                          <Autocomplete
                                            id='designatorsAutocomplete'
                                            size='small'
                                            style={{
                                              width: '100%',
                                              marginTop: '10px',
                                              marginBottom: '10px'
                                            }}
                                            key={index}
                                            options={
                                              boardTypes
                                                ? boardTypes
                                                    .filter(boardOption => boardOption.boardTypeName === board.boardTypeName)
                                                    .map(currentBoard => currentBoard.designators)
                                                    .reduce((first, second) => {
                                                      return first.concat(second)
                                                    }, [])
                                                    .sort()
                                                : []
                                            }
                                            renderInput={params => (
                                              <TextField size='small' {...params} label='Designator' variant='outlined' />
                                            )}
                                            value={newDesignator}
                                            loading={boardTypesLoading}
                                            onKeyDown={e => {
                                              if (e.key === 'Enter') setDesignator(newDesignator, index)
                                            }}
                                            onInputChange={(_, e) => setNewDesignator(e.replace(',', ' -'), index)}
                                            onBlur={e => setDesignator(e.target.value, index)}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            disableClearable={true}
                                            freeSolo={true}
                                          />
                                          <IconButton
                                            id='designatorsAddButton'
                                            style={{
                                              marginTop: '10px'
                                            }}
                                            onClick={() => setDesignator(newDesignator, index)}
                                            title='Add Designator'
                                          >
                                            <AddCircleOutline />
                                          </IconButton>
                                        </div>
                                        <div>
                                          {board.designators.map((designator, desIndex) => {
                                            return (
                                              <Chip
                                                id={`designator${desIndex}Chip`}
                                                key={desIndex}
                                                label={designator}
                                                variant='outlined'
                                                onDelete={() => {
                                                  deleteDesignator(index, desIndex)
                                                }}
                                              />
                                            )
                                          })}
                                        </div>
                                        <CenteredDiv>
                                          <RegularButton
                                            id={`board${index}RemoveButton`}
                                            onClick={() => removeBoard(index)}
                                            size='small'
                                            style={{
                                              marginTop: '10px',
                                              border: '2px solid red',
                                              color: 'red',
                                              display: 'block'
                                            }}
                                          >
                                            Remove Board
                                          </RegularButton>
                                        </CenteredDiv>
                                      </Stack>
                                    }
                                  />
                                )
                              })}
                              <Grid>
                                <FormControlLabel
                                  style={{ margin: '19px -10px 0px -10px' }}
                                  control={
                                    <Switch
                                      id='isRapidRepairSwitch'
                                      checked={rma.isRapidRepair ? true : false}
                                      onChange={e =>
                                        setRma(f => ({
                                          ...f,
                                          isRapidRepair: rma.isRapidRepair ? false : true
                                        }))
                                      }
                                    />
                                  }
                                  label='Rapid Repair'
                                />
                              </Grid>
                              <Grid>
                                <FormControlLabel
                                  style={{ margin: '0px -10px' }}
                                  control={
                                    <Switch
                                      id='outOfBoxFailureSwitch'
                                      checked={rma.outOfBoxFailure === 'Yes' || rma.outOfBoxFailure === 'Y' ? true : false}
                                      onChange={e =>
                                        setRma(f => ({
                                          ...f,
                                          outOfBoxFailure:
                                            rma.outOfBoxFailure === 'Yes' || rma.outOfBoxFailure === 'Y' ? 'No' : 'Yes'
                                        }))
                                      }
                                    />
                                  }
                                  label='Out Of Box Failure'
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid container justifyContent='space-evenly' direction='column'>
                              <Grid>
                                <TextField
                                  id='boardRevTextField'
                                  size='small'
                                  onChange={e => setRma(f => ({ ...f, boardRev: e.target.value.replace(',', ' -') }))}
                                  value={rma.boardRev}
                                  variant='outlined'
                                  label='Board Rev'
                                  sx={classes.formEntry}
                                />
                              </Grid>
                              <Grid>
                                <Tooltip title={'This is the repair fee amount.'}>
                                  <TextField
                                    id='repairFeeTextField'
                                    size='small'
                                    label='Repair Fee'
                                    variant='outlined'
                                    value={rma.amountSpent ? rma.amountSpent : 0}
                                    onChange={e => setRma(f => ({ ...f, amountSpent: e.target.value }))}
                                    InputProps={{
                                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                    }}
                                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid>
                                <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                                  <DatePicker
                                    id='completedDatePicker'
                                    label='Completed Date'
                                    inputFormat='MM/DD/YYYY'
                                    value={rma.completedDate}
                                    onChange={date => setRma(f => ({ ...f, completedDate: date }))}
                                    renderInput={params => <TextField size='small' {...params} />}
                                  />
                                </div>
                              </Grid>
                              <Grid>
                                <Autocomplete
                                  id='initalNameAutocomplete'
                                  size='small'
                                  style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                  options={
                                    initials
                                      ? initials
                                          .filter(option => !option.isArchived)
                                          .map(initial => initial.initialName)
                                          .sort()
                                      : []
                                  }
                                  renderInput={params => (
                                    <TextField size='small' {...params} label='Repair Tech.' variant='outlined' />
                                  )}
                                  value={rma.initials}
                                  loading={initialsLoading}
                                  onInputChange={(_, e) => setRma(f => ({ ...f, initials: e.toUpperCase().replace(',', ' -') }))}
                                  isOptionEqualToValue={(option, value) => option.value === value.value}
                                  disableClearable={true}
                                  freeSolo={true}
                                />
                              </Grid>
                              <Grid>
                                <Tooltip title={'These are notes our technicians make about the repair.'}>
                                  <TextField
                                    id='repairCommentTextField'
                                    size='small'
                                    multiline
                                    label='Repair Comment'
                                    variant='outlined'
                                    value={rma.repairComments}
                                    onChange={e => setRma(f => ({ ...f, repairComments: e.target.value.replace(',', ' -') }))}
                                    sx={classes.formEntry}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid>
                            <Grid container justifyContent='space-evenly' direction='column'>
                              <Grid>
                                <Tooltip title={"This is what the repair fee would have been if it hadn't been waived."}>
                                  <TextField
                                    id='amountWaivedTextField'
                                    size='small'
                                    onChange={e => setRma(f => ({ ...f, amountWaived: e.target.value }))}
                                    value={rma.amountWaived ? rma.amountWaived : ''}
                                    variant='outlined'
                                    label='Amount Waived'
                                    InputProps={{
                                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                                    }}
                                    sx={classes.formEntry}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid>
                                <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                                  <DatePicker
                                    id='originalShipDatePicker'
                                    label='Original Ship Date'
                                    inputFormat='MM/DD/YYYY'
                                    value={rma.originalShipDate}
                                    onChange={date => setRma(f => ({ ...f, originalShipDate: date }))}
                                    renderInput={params => <TextField size='small' {...params} />}
                                  />
                                </div>
                              </Grid>
                              <Grid>
                                <Tooltip title={'This is the cause/reason why the failure occurred.'}>
                                  <Autocomplete
                                    id='problemsFoundAutocomplete'
                                    size='small'
                                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                    options={
                                      problemsFound
                                        ? problemsFound
                                            .filter(option => !option.isArchived)
                                            .map(problemFound => problemFound.problemsFoundName)
                                            .sort()
                                        : []
                                    }
                                    renderInput={params => (
                                      <TextField {...params} size='small' label='Cause of Problem Found' variant='outlined' />
                                    )}
                                    value={rma.problemFound}
                                    loading={problemsFoundLoading}
                                    onInputChange={(_, e) => setRma(f => ({ ...f, problemFound: e.replace(',', ' -') }))}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    disableClearable={true}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid>
                                <Tooltip title={'This is what the customer stated the issue they saw was.'}>
                                  <Autocomplete
                                    id='customerComplaintAutocomplete'
                                    size='small'
                                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                    options={
                                      customerComplaints
                                        ? customerComplaints
                                            .filter(option => !option.isArchived)
                                            .map(customerComplaint => customerComplaint.customerComplaintName)
                                            .sort()
                                        : []
                                    }
                                    renderInput={params => (
                                      <TextField {...params} size='small' label='Customer Complaint' variant='outlined' />
                                    )}
                                    value={rma.customerComplaint}
                                    loading={customerComplaintsLoading}
                                    onInputChange={(_, e) => {
                                      setRma(f => ({
                                        ...f,
                                        customerComplaint: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                                      }))
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    disableClearable={true}
                                    freeSolo={true}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid>
                                <Tooltip title={'This is what our technicians found to be the issue.'}>
                                  <Autocomplete
                                    id='finalDeterminationAutocomplete'
                                    size='small'
                                    style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                    options={
                                      customerComplaints
                                        ? customerComplaints
                                            .filter(option => !option.isArchived)
                                            .map(customerComplaint => customerComplaint.customerComplaintName)
                                            .sort()
                                        : []
                                    }
                                    renderInput={params => (
                                      <TextField {...params} size='small' label='Final Determination' variant='outlined' />
                                    )}
                                    value={rma.finalDetermination}
                                    loading={customerComplaintsLoading}
                                    onInputChange={(_, e, r) => {
                                      setRma(f => ({
                                        ...f,
                                        finalDetermination: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                                      }))
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    disableClearable={true}
                                    freeSolo={true}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid>
                                <Autocomplete
                                  id='repairTypeAutocomplete'
                                  size='small'
                                  style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
                                  options={
                                    repairTypes
                                      ? repairTypes
                                          .filter(option => !option.isArchived)
                                          .map(repairType => repairType.repairTypeName)
                                          .sort()
                                      : []
                                  }
                                  renderInput={params => (
                                    <TextField size='small' {...params} label='Repair Type' variant='outlined' />
                                  )}
                                  value={rma.repairType}
                                  loading={repairTypesLoading}
                                  onInputChange={(_, e) =>
                                    setRma(f => ({
                                      ...f,
                                      repairType: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -')
                                    }))
                                  }
                                  isOptionEqualToValue={(option, value) => option.value === value.value}
                                  disableClearable={true}
                                  freeSolo={true}
                                />
                              </Grid>
                              <Grid>
                                <div style={{ ...classes.formEntry, display: 'inline-block' }}>
                                  <DatePicker
                                    id='buildDatePicker'
                                    label='Build Date'
                                    inputFormat='MM/DD/YYYY'
                                    value={rma.buildDate}
                                    onChange={date => setRma(f => ({ ...f, buildDate: date }))}
                                    renderInput={params => <TextField size='small' {...params} />}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </HasAccess>
                <HasAccess allowedRoles={['RMA Read Only']} env={env}>
                  <ReadOnlyDetailView rma={rma} />
                </HasAccess>
              </Grid>
            </div>
            <CardActions sx={{ float: 'right' }}>
              <HasAccess allowedRoles={['RMA User']} env={env}>
                <RegularButton id='rmaDetailCancelButton' onClick={returnHome}>
                  Cancel
                </RegularButton>
                <PrimaryButton id='rmaDetailSaveButton' onClick={handleSave}>
                  Save Changes
                </PrimaryButton>
              </HasAccess>
            </CardActions>
          </CardContent>
        </Card>
      </CenteredDiv>
    </>
  )
}
