import { useMsal } from '@azure/msal-react'
import { Autocomplete, DialogContent, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { PrimaryButton, RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import ListOptionsApi from '../api/ListOptionsApi'
import RmaApi from '../api/RmaApi'
import { DEFAULT_FORM } from '../ListOptions'

const classes = {
  formEntry: {
    margin: '10px'
  }
}

export default function EditRmaModal({
  open,
  onClose,
  repairTypes,
  repairTypesLoading,
  repairTypesRefetch,
  dealers,
  dealerLoading,
  dealerRefetch,
  productTypes,
  productTypesLoading,
  productTypesRefetch,
  products,
  productsLoading,
  productsRefetch,
  refetchRma,
  rma,
  customers,
  customersLoading,
  customersRefetch,
  states,
  statesLoading,
  statesRefetch,
  countries,
  countriesLoading,
  countriesRefetch,
  cities,
  citiesLoading,
  citiesRefetch
}) {
  const { instance, accounts } = useMsal()
  const [form, setForm] = useState(DEFAULT_FORM)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [dealerOrCustomer, setDealerOrCustomer] = useState(true)
  const [dataIsValid, setDataIsValid] = useState(true)
  const [dealerIsValid, setDealerIsValid] = useState(true)
  const [customerIsValid, setCustomerIsValid] = useState(true)
  const [stateIsValid, setStateIsValid] = useState(true)
  const [countryIsValid, setCountryIsValid] = useState(true)
  const [productTypeIsValid, setProductTypeIsValid] = useState(true)
  const [productIsValid, setProductIsValid] = useState(true)
  const [repairTypeIsValid, setRepairTypeIsValid] = useState(true)
  const [cityIsValid, setCityIsValid] = useState(true)
  const [serialNumberIsValid, setSerialNumberIsValid] = useState(true)
  const [rmaNumberIsValid, setRmaNumberIsValid] = useState(true)
  const [openDateIsValid, setOpenDateIsValid] = useState(true)
  const dealerError = dealerIsValid && isValidOrEmpty(form.customer, customers, 'customerName')
  const customerError = customerIsValid && isValidOrEmpty(form.dealer, dealers, 'dealerName')

  useEffect(() => {
    setDataIsValid(
      (dealerError || customerError) &&
        stateIsValid &&
        countryIsValid &&
        productTypeIsValid &&
        productIsValid &&
        repairTypeIsValid &&
        cityIsValid &&
        serialNumberIsValid &&
        rmaNumberIsValid &&
        openDateIsValid
    )
  }, [
    dealerError,
    customerError,
    stateIsValid,
    countryIsValid,
    productTypeIsValid,
    productIsValid,
    repairTypeIsValid,
    cityIsValid,
    serialNumberIsValid,
    rmaNumberIsValid,
    openDateIsValid
  ])

  useEffect(() => {
    if (rma) {
      setForm(rma)
    }
  }, [rma])

  useEffect(() => {
    if (form.customer !== '' || form.dealer !== '') {
      setDealerOrCustomer(true)
    } else {
      setDealerOrCustomer(false)
    }
  }, [form.dealer, form.customer])

  function closeModal() {
    onClose()
  }

  function cancelModal() {
    setForm(rma)
    onClose()
  }

  const handleSubmit = () => {
    let existingDealer = false
    let existingProductType = false
    let existingProduct = false
    let existingRepairType = false
    let existingCustomer = false
    let existingState = false
    let existingCountry = false
    let existingCity = false
    for (let i = 0; i < dealers.length; i++) {
      if (dealers[i].dealerName === form.dealer) existingDealer = true
    }
    for (let i = 0; i < productTypes.length; i++) {
      if (productTypes[i].productTypeName === form.productType) existingProductType = true
    }
    for (let i = 0; i < products.length; i++) {
      if (products[i].productName === form.product) existingProduct = true
    }
    for (let i = 0; i < repairTypes.length; i++) {
      if (repairTypes[i].repairTypeName === form.repairType) existingRepairType = true
    }
    for (let i = 0; i < customers.length; i++) {
      if (customers[i].customerName === form.customer) existingCustomer = true
    }
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateName === form.state) existingState = true
    }
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].countryName === form.country) existingCountry = true
    }
    for (let i = 0; i < cities.length; i++) {
      if (cities[i].cityName === form.city) existingCity = true
    }
    if (!existingDealer && form.dealer !== '' && form.dealer) createNewDropOption(form.dealer, 'Dealer', dealerRefetch)
    if (!existingProductType && form.productType !== '' && form.productType)
      createNewDropOption(form.productType, 'ProductType', productTypesRefetch)
    if (!existingProduct && form.product !== '' && form.product) createNewDropOption(form.product, 'Product', productsRefetch)
    if (!existingRepairType && form.repairType !== '' && form.repairType)
      createNewDropOption(form.repairType, 'RepairType', repairTypesRefetch)
    if (!existingCustomer && form.customer !== '' && form.customer)
      createNewDropOption(form.customer, 'Customer', customersRefetch)
    if (!existingState && form.state !== '' && form.state) createNewDropOption(form.state, 'State', statesRefetch)
    if (!existingCountry && form.country !== '' && form.country) createNewDropOption(form.country, 'Country', countriesRefetch)
    if (!existingCity && form.city !== '' && form.city) createNewDropOption(form.city, 'City', citiesRefetch)
    editRma()
  }

  async function createNewDropOption(OptionEntry, ListType, refetch) {
    let key = enqueueSnackbar(`Saving ${ListType}...`, SnackbarVariants.LOADING)
    await ListOptionsApi.addNewDropOption(instance, accounts, { [`${ListType}Name`]: OptionEntry }, ListType)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar(`New ${ListType} was Added`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`Failed to Add ${ListType}`, SnackbarVariants.ERROR)
      })
      .finally(refetch)
  }

  async function editRma() {
    let key = enqueueSnackbar('Saving RMA...', SnackbarVariants.LOADING)
    await RmaApi.updateRma(instance, accounts, form)
      .then(() => {
        closeSnackbar(key)
        enqueueSnackbar('RMA was updated', SnackbarVariants.SUCCESS)
        closeModal()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Failed to update RMA', SnackbarVariants.ERROR)
      })
      .finally(refetchRma)
  }

  function isValidOrEmpty(value, options, fieldName) {
    if (value === '' || validateStrict(value, options, fieldName)) {
      return true
    } else return false
  }

  function validateStrict(value, options, fieldName) {
    let existingOption = false

    for (let i = 0; i < options.length; i++) {
      if (options[i][fieldName] === value) {
        if (options[i]['isArchived']) {
        } else {
          existingOption = true
        }
      }
    }
    return existingOption
  }

  return (
    <>
      <SlideUpDialog
        id='editRmaModal'
        open={open}
        onClose={cancelModal}
        title={<h3 style={{ margin: 0 }}>Edit RMA</h3>}
        actions={
          <>
            <RegularButton id='editRmaCancelButton' onClick={cancelModal}>
              Cancel
            </RegularButton>
            <PrimaryButton id='editRmaSaveButton' onClick={handleSubmit} disabled={!dataIsValid}>
              Save Changes
            </PrimaryButton>
          </>
        }
      >
        <DialogContent>
          <TextField
            id='rmaNumberTextField'
            size='small'
            required
            type='number'
            onChange={e => {
              setForm(f => ({ ...f, rmaNumber: e.target.value }))
              setRmaNumberIsValid(e.target.value !== '')
            }}
            value={form.rmaNumber}
            variant='outlined'
            label='RMA Number'
            style={{ ...classes.formEntry, width: '205px' }}
          />
          <div style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}>
            <DatePicker
              id='openDateStartDatePicker'
              label='Open Date*'
              inputFormat='MM/DD/YYYY'
              value={form.openDate}
              onChange={date => {
                setForm(f => ({ ...f, openDate: date }))
                setOpenDateIsValid(date ? date._isValid : false)
              }}
              renderInput={params => <TextField size='small' {...params} />}
            />
          </div>
          <Autocomplete
            id='dealerNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={dealers ? dealers.map(dealer => dealer.dealerName).sort() : []}
            renderInput={params => (
              <TextField size='small' {...params} required={!dealerOrCustomer} label='Dealer' variant='outlined' />
            )}
            value={form.dealer}
            loading={dealerLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, dealer: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
              setDealerIsValid(validateStrict(e, dealers, 'dealerName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <Autocomplete
            id='customerNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={customers ? customers.map(customer => customer.customerName).sort() : []}
            renderInput={params => (
              <TextField size='small' {...params} required={!dealerOrCustomer} label='Customer' variant='outlined' />
            )}
            value={form.customer}
            loading={customersLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, customer: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
              setCustomerIsValid(validateStrict(e, customers, 'customerName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <Autocomplete
            id='cityNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={cities ? cities.map(city => city.cityName).sort() : []}
            renderInput={params => <TextField size='small' {...params} required label='City' variant='outlined' />}
            value={form.city}
            loading={citiesLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, city: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
              setCityIsValid(e !== '')
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
            freeSolo={true}
          />
          <Autocomplete
            id='stateNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={
              states
                ? states
                    .filter(option => !option.isArchived)
                    .map(state => state.stateName)
                    .sort()
                : []
            }
            renderInput={params => <TextField size='small' {...params} required label='State' variant='outlined' />}
            value={form.state}
            loading={statesLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, state: e.toUpperCase().replace(',', ' -') }))
              setStateIsValid(validateStrict(e, states, 'stateName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <Autocomplete
            id='countryNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={
              countries
                ? countries
                    .filter(option => !option.isArchived)
                    .map(country => country.countryName)
                    .sort()
                : []
            }
            renderInput={params => <TextField size='small' {...params} required label='Country' variant='outlined' />}
            value={form.country}
            loading={countriesLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, country: e.toUpperCase().replace(',', ' -') }))
              setCountryIsValid(validateStrict(e, countries, 'countryName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />

          <Autocomplete
            id='productTypeNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={
              productTypes
                ? productTypes
                    .filter(option => !option.isArchived)
                    .map(productType => productType.productTypeName)
                    .sort()
                : []
            }
            renderInput={params => <TextField size='small' {...params} required label='Product Type' variant='outlined' />}
            value={form.productType}
            loading={productTypesLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, productType: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
              setProductTypeIsValid(validateStrict(e, productTypes, 'productTypeName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <Autocomplete
            id='productNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={
              products
                ? products
                    .filter(option => !option.isArchived)
                    .map(product => product.productName)
                    .sort()
                : []
            }
            renderInput={params => <TextField size='small' {...params} required label='Product' variant='outlined' />}
            value={form.product}
            loading={productsLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, product: e.toUpperCase().replace(',', ' -') }))
              setProductIsValid(validateStrict(e, products, 'productName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <TextField
            id='serialNumberTextField'
            size='small'
            required
            onChange={e => {
              setForm(f => ({ ...f, serialNumber: e.target.value.toUpperCase().replace(' ', '-').replace(',', '-') }))
              setSerialNumberIsValid(e !== '')
            }}
            value={form.serialNumber}
            variant='outlined'
            label='Serial Number'
            style={{ ...classes.formEntry, width: '205px' }}
          />
          <Autocomplete
            id='repairTypeNameAutoComplete'
            size='small'
            style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}
            options={
              repairTypes
                ? repairTypes
                    .filter(option => !option.isArchived)
                    .map(repairType => repairType.repairTypeName)
                    .sort()
                : []
            }
            renderInput={params => <TextField size='small' {...params} required label='Repair Type' variant='outlined' />}
            value={form.repairType}
            loading={repairTypesLoading}
            onInputChange={(_, e) => {
              setForm(f => ({ ...f, repairType: e.replace(/\b\w/g, m => m.toUpperCase()).replace(',', ' -') }))
              setRepairTypeIsValid(validateStrict(e, repairTypes, 'repairTypeName'))
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableClearable={true}
          />
          <div style={{ ...classes.formEntry, width: '205px', display: 'inline-block' }}>
            <DatePicker
              id='originalShipDatePicker'
              label='Original Ship Date'
              inputFormat='MM/DD/YYYY'
              value={form.originalShipDate}
              onChange={date => setForm(f => ({ ...f, originalShipDate: date }))}
              renderInput={params => <TextField size='small' {...params} />}
            />
          </div>
        </DialogContent>
      </SlideUpDialog>
    </>
  )
}
