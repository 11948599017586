const customerComplaintMap = {
  'Serial Com Issues': [
    'bad port 1',
    'bad port 2',
    'bad port 3',
    'bad port 4',
    'bad port 5',
    'port 1 not working',
    'port 2 not working',
    'port 3 not working',
    'port 4 not working',
    'port 5 not working',
    'no 232',
    'no 485',
    'no data output'
  ],
  'Ethernet Issues': ['bad ethernet port(s)', 'bad lan', 'bad wan'],
  'SDLC Issues': ['no sdlc output', 'no sdlc', 'no transmit or receive lights for the sdlc'],
  'Power Issues': ['no power', 'intermittent', 'keeps rebooting', 'no boot', 'no comms', 'power surge'],
  'No Detections': ['all rf channel failure', 'no detections', 'no trackers'],
  'Missed Detections': ['one or more rf channel failure', 'dead spot', 'failsafe', 'dropped calls', 'missing calls'],
  'Bad Detections': ['false calls', 'constant call', 'low/high speed'],
  'Physical Damage': [
    'physical damage',
    'broken lcd',
    'broken switch',
    'knockdown/dropped',
    'broken connector/ground lug',
    'loose screws',
    'missing screws',
    'no display',
    'bad keypad',
    'casing',
    'ground screw fail'
  ],
  'ADC Failure': ['adc failure'],
  'Data Issues': ['data loss in memory', 'internal clock drifts', 'not keeping time']
}

export default customerComplaintMap
