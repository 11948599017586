import dayjs from 'dayjs'

const END_HOUR = 23
const END_MINUTE = 59
const END_SECOND = 59
const END_MILLISECOND = 999

let defaultDate = new Date()
defaultDate.setHours(END_HOUR, END_MINUTE, END_SECOND, END_MILLISECOND)

export const DEFAULT_FORM = {
  rmaNumber: '',
  openDate: null,
  dealer: '',
  customer: '',
  city: '',
  state: '',
  productType: '',
  product: '',
  serialNumber: '',
  originalShipDate: null,
  entryNumber: 0,
  boardType: [{ boardTypeName: '', designators: [] }],
  boardRev: '',
  problemFound: '',
  issue: '',
  designator: '',
  replacedWithHittite: '',
  repairComments: '',
  completedDate: dayjs(defaultDate),
  initials: '',
  daysOpen: '',
  customerComplaint: '',
  repairType: '',
  outOfBoxFailure: '',
  buildDate: null,
  amountSpent: 0,
  isRapidRepair: false,
  amountWaived: 0,
  country: '',
  finalDetermination: ''
}
