import { acquireAccessToken } from '@wavetronix/common-components'
import axios from 'axios'
import { env } from '../index.js'

let instance = null

class ListOptionsAPi {
  getListOptions = async (msalInstance, accounts, list) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.rmaURL}/api/listoptions/${list}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  addNewDropOption = async (msalInstance, accounts, newOption, optionType) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .post(`${env.urls.rmaURL}/api/listoptions/${optionType}`, newOption, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }

  archiveListOption = async (msalInstance, accounts, option, optionList) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .put(`${env.urls.rmaURL}/api/listoptions/${optionList}`, option, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new ListOptionsAPi()
  }
  return instance
}
export default getInstance()
