import { Card, DialogContent } from '@mui/material'
import { CustomDataGrid, SlideUpDialog } from '@wavetronix/common-components'
import { useHistory } from 'react-router-dom'
import { columns } from '../RmaListPage'

export default function StatsRmaModal({ open, onClose, rmas }) {
  const history = useHistory()
  return (
    <SlideUpDialog id='statsRmaModal' open={open} onClose={onClose} fullScreen title={<h3 style={{ margin: 0 }}>RMAs</h3>}>
      <DialogContent>
        <Card style={{ minWidth: 1400 }}>
          <CustomDataGrid
            rows={rmas ? rmas : []}
            columns={columns}
            onRowClick={rma => history.push(`/rma?id=${rma.id}`)}
            cursor='pointer'
          />
        </Card>
      </DialogContent>
    </SlideUpDialog>
  )
}
