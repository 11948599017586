import { WidthProvider, Responsive } from 'react-grid-layout'

const ReactGridLayout = WidthProvider(Responsive)

export const generateLayout = (data, col) => {
  return data.map((d, index) => {
    return {
      x: index % col,
      y: 0,
      w: 1,
      h: 1,
      i: d
    }
  })
}

export default function CustomLegend({ legend }) {
  return (
    <div style={{ margin: '0px 0px 0px 60px', maxHeight: '175px', overflowY: 'scroll' }}>
      <ReactGridLayout
        className='layout'
        isDraggable={false}
        isResizable={false}
        rowHeight={18}
        margin={[0, 0]}
        breakpoints={{ xl: 1250, lg: 1000, md: 800, sm: 700, xs: 400, xxs: 0 }}
        cols={{ xl: 6, lg: 5, md: 4, sm: 3, xs: 3, xxs: 2 }}
        layouts={{
          xl: generateLayout(Object.keys(legend), 6),
          lg: generateLayout(Object.keys(legend), 5),
          md: generateLayout(Object.keys(legend), 4),
          sm: generateLayout(Object.keys(legend), 3),
          xs: generateLayout(Object.keys(legend), 3),
          xxs: generateLayout(Object.keys(legend), 2)
        }}>
        {Object.keys(legend).map(option => {
          return (
            <div key={option} style={{ display: 'flex' }}>
              <div
                style={{
                  borderRadius: '6px',
                  backgroundColor: legend[option],
                  width: '15px',
                  height: '15px'
                }}
              />
              <div
                style={{
                  marginLeft: '10px',
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>{`${option}`}</div>
            </div>
          )
        })}
      </ReactGridLayout>
    </div>
  )
}
