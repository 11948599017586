import { WtxColors } from '@wavetronix/common-components'

export default function ConsolidatedTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    let result = ''
    if (payload[0].payload) {
      if (payload[0].payload.subcategories) {
        for (let subcategory of payload[0].payload.subcategories) {
          result += `${subcategory.item}: ${subcategory.count}<br>`
        }
        return (
          <div
            style={{
              background: 'white',
              border: 'solid 1px',
              borderColor: WtxColors.CONCRETE,
              padding: '10px'
            }}>
            {payload[0].payload.item}
            <div
              style={{
                color: WtxColors.IQ_BLUE,
                marginTop: '5px'
              }}>
              count: {payload[0].payload.count}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: result }}
              style={{
                color: WtxColors.IQ_BLUE,
                fontSize: '10pt',
                marginTop: '5px'
              }}></div>
          </div>
        )
      }
    }
    return (
      <div
        style={{
          background: 'white',
          border: 'solid 1px',
          borderColor: WtxColors.CONCRETE,
          padding: '10px'
        }}>
        {payload[0].payload.item}
        <div
          style={{
            color: WtxColors.IQ_BLUE,
            marginTop: '5px'
          }}>
          count: {payload[0].payload.count}
        </div>
      </div>
    )
  }
}
